import React from "react";
// styling
import { css } from "emotion";
import colors from "../../../style/colors";
import { AccountAlertIcon, AccountCheckIcon, CheckIcon } from "mdi-react";
import punchStatuses from "../../../config/punchStatuses";

function PunchStatus({ status, offsetBottom = true }) {
  return (
    <div className={container(offsetBottom) + ` ${status}`}>
      {status === punchStatuses.open && (
        <>
          <AccountAlertIcon /> {status}
        </>
      )}
      {status === punchStatuses.cleared && (
        <>
          <AccountCheckIcon /> {status}
        </>
      )}
      {status === punchStatuses.closed && (
        <>
          <CheckIcon /> {status}
        </>
      )}
    </div>
  );
}

const container = (offsetBottom) => css`
  font-weight: 700;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-bottom: ${offsetBottom ? "-0.25rem" : 0};
  }

  &.Open {
    color: ${colors.red};
  }
  &.Cleared {
    color: ${colors.vividBlue};
  }
  &.Closed {
    color: ${colors.green};
  }
`;

export default PunchStatus;
