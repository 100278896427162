import axios from "axios";
import {
  GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
  GET_APP_CONFIG_FAILURE,
  SET_APP_LANGUAGE,
  CLEAR_APP_CONFIG_LOGIN_TYPES,
} from "./actionTypes";
import getAppName from "../utilities/get-app-name";

import setIcons from "../utilities/set-icons";

export function getAppConfig() {
  return async function (dispatch) {
    dispatch({
      type: GET_APP_CONFIG,
    });

    // For semco-apps don't get api-config
    let manifestEl = document.getElementById("elManifest");
    let apiURL = manifestEl.getAttribute("data-base-url");

    // Special value. You can set base-url to CURRENT_DOMAIN to use the current domain instead of a external domain
    if (apiURL === "CURRENT_DOMAIN") apiURL = `${window.location.origin}/api/`;

    // Special value for local development
    if (process.env.NODE_ENV === "development") apiURL = `http://localhost:3030/api/`;

    axios
      .get(`${apiURL}apps/config/${getAppName()}`)
      .then(({ data }) => {
        // Set document title
        document.title = data.title;

        // Set icons (favicon and touch-icons)
        setIcons(data.icon);

        return dispatch({
          type: GET_APP_CONFIG_SUCCESS,
          payload: {
            appId: data.id,
            title: data.title,
            primaryColor: data.primaryColor,
            apiUrl: apiURL,
            icon: data.icon,
            frontPageImage: data.frontPageImage,
            customerLogo: data.customerLogo,
            allowedLoginTypes: data.allowedLoginTypes,
            enableHighscore: data.enableHighscore,
          },
        });
      })
      .catch(() => {
        return dispatch({
          type: GET_APP_CONFIG_FAILURE,
        });
      });
  };
}

export function setAppLanguage(language) {
  return {
    type: SET_APP_LANGUAGE,
    payload: language,
  };
}

export function clearLoginTypes() {
  return {
    type: CLEAR_APP_CONFIG_LOGIN_TYPES,
  };
}
