import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal, notification } from "antd";
import moment from "moment";
import * as yup from "yup";
import { isEmpty, sortBy } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import req from "../../utilities/request-utility";

// css
import { css } from "emotion";
import colors from "../../style/colors";

// actions
import { hideModalPage } from "../../actions/uiActions";

// ui-components
import ScrollView from "../ui/ScrollView";
import Page from "../ui/Page";
import DropDown from "../ui/DropDown";
import DatePicker from "../ui/DatePicker";
import Button from "../ui/Button";
import common from "../../style/common";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import TimeRegistrationList from "../semco-time/TimeRegistrationList";

import { useDropdownData } from "./hooks";

const WorkTimeRegistration = ({ addRegistration, registration, updateRegistration }) => {
  const dispatch = useDispatch();
  const lang = useSelector(({ language }) => language.language);

  // Api data
  const [foremen, setForemen] = useState([]);

  // Local data
  const [isFormReady, setIsFormReady] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [errorLoadingFormData, setErrorLoadingFormData] = useState(false); //error page
  const [tradeCodesList, setTradeCodesList] = useState([]);

  const getForemen = () => {
    try {
      setIsFormReady(false);

      req()(`semcotime/foremen/`).then(({ data }) => {
        const assignedIds = data.assigned.map((d) => d.id);

        setForemen([
          ...data.assigned,
          { disabled: true, id: "", name: "------------------" },
          ...data.all.filter((d) => !assignedIds.includes(d.id)),
        ]);
      });

      setIsFormReady(true);
    } catch (err) {
      setErrorLoadingFormData(true);
    }
  };

  const schema = yup.object().shape({
    //validations
    date: yup.string().required("Date is required"),
    detailedReportingEnabled: yup.boolean(),
    location: yup.string().required("Location is required"),
    project: yup.string().required("Project is required"),
    subProject: yup.string().required("Sub Project is required"),
    activity: yup.string().required("Project Activity is required"),
    trade: yup.string().required("Trade is required"),
    foreman: yup.string().required("Foreman is required"),
    timeRegistrations: yup
      .array()
      .of(
        yup.object().shape({
          savedByBlueCollar: yup.boolean(),
        })
      )
      .compact((v) => !v.savedByBlueCollar) //check if there is time registration that is not SAVED
      .min(1, "Time registration must have at least 1 saved registation"),
  });

  const defaultValues = {
    date: "",
    detailedReportingEnabled: false,
    location: "",
    project: "",
    subProject: "",
    activity: "",
    trade: "",
    foreman: "",
    endOfRotation: false,
    timeRegistrations: [],
    nptHott: [],
    type: "time",
  };

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  // console.log("errors: ", errors);
  // console.log("watch: ", watch());

  const {
    dabLocations,
    dabLocationsIsFetching,
    dabTradeCodes,
    dabTradeIsFetching,
    defaultEmployeeValues,
    defaultEmployeeValuesStatus,
    defaultEmployeeValuesIsFetching,
    defaultProjectSubProjectAndActivityData,
    defaultProjectSubProjectAndActivityStatus,
    projectData,
    projectActivityData,
    projectStatus,
    projectActivityStatus,
    subProjectData,
    subProjectStatus,
  } = useDropdownData(watch());

  const getProjectActivityPlaceholder = () => {
    if (projectActivityStatus === "loading" || projectActivityStatus === "idle") {
      return lang.loading;
    } else if (projectActivityData.length === 0) {
      return lang.noActivities;
    } else {
      return lang.chooseActivity;
    }
  };

  const getSubProjectPlaceholder = () => {
    if (subProjectStatus === "loading" || subProjectStatus === "idle") {
      return lang.loading;
    } else if (subProjectData.length === 0) {
      return "No subprojects found";
    } else {
      return lang.chooseASubproject;
    }
  };

  useEffect(() => {
    setTradeCodesList(dabTradeCodes);
  }, [dabTradeCodes.length]);

  useEffect(() => {
    getForemen();

    // deletes old form layout in local storage
    let localStorageForm = localStorage.getItem("semcotime-last-time-input");
    if (localStorageForm) {
      if (
        !JSON.parse(localStorageForm).hasOwnProperty("detailedReportingEnabled") &&
        !JSON.parse(localStorageForm).hasOwnProperty("nptHott")
      ) {
        console.log("-- OLD FORM DETECTED IS DELETED --");
        localStorage.removeItem("semcotime-last-time-input");
      }
    }
  }, []);

  useEffect(() => {
    // form onload edit or from local storage
    if (defaultEmployeeValuesStatus === "success" && isEmpty(registration)) {
      const { employeeTrade = "" } = defaultEmployeeValues;

      // to condition when editing
      setValue("trade", employeeTrade);
    }
  }, [defaultEmployeeValuesIsFetching]);

  useEffect(() => {
    if (
      !defaultEmployeeValuesIsFetching &&
      defaultEmployeeValues.employeeTrade &&
      getValues("trade") !== defaultEmployeeValues.employeeTrade
    ) {
      const defaultEmployeeTrade = dabTradeCodes.filter((d) => d.DabTradeId === defaultEmployeeValues.employeeTrade)[0];
      notification.warning({
        description: `${lang.tradeNotDefault} (${defaultEmployeeTrade.DabTradeId} - ${defaultEmployeeTrade.DabTradeDesc}).`,
      });
    }
  }, [getValues("trade")]);

  useEffect(() => {
    if (registration) {
      console.log("--LOAD REG FORM--");
      //if edit
      reset(registration.data);
    } else {
      // if add with last form used
      const lastForm = localStorage.getItem("semcotime-last-time-input");
      if (lastForm) {
        console.log("--LOAD LOCAL FORM--");
        reset(JSON.parse(lastForm));
      }
    }
  }, [registration]);

  if (!isFormReady && !errorLoadingFormData) {
    return (
      <Page className={componentStyles()}>
        <InlineSpinner style={{ marginTop: "1rem" }} />
      </Page>
    );
  }

  if (errorLoadingFormData) {
    return (
      <Page className={componentStyles()}>
        <StatusBox
          title="Error loading data"
          content="The system were unable to get data for this record. Please try again later or contact an administrator"
        />
      </Page>
    );
  }

  const onSubmit = (formData, toOtherDay = false) => {
    const proceedToSubmit = () => {
      setSubmittingForm(true);
      const request = registration ? updateRegistration : addRegistration;

      Promise.all([request({ ...formData, type: "time" })]) // to fix, not setting type as default
        .then(() => {
          let nextDay = "";
          if (toOtherDay) {
            localStorage.setItem(
              "semcotime-last-time-input",
              JSON.stringify({ ...formData, date: moment(formData.date).add(1, "days").format("YYYY-MM-DD") })
            );
            nextDay = moment(formData.date).add(1, "days").format("YYYY-MM-DD");
            setValue("date", nextDay);
            setSubmittingForm(false);
          } else {
            dispatch(hideModalPage());
          }

          notification.success({
            duration: 7,
            message: "SUCCESS",
            description: `${lang.savedRegistrationToastContent}. ${
              toOtherDay ? lang.timeRegistrationFormDateNowIs.replace("{{placeholder}}", nextDay) : ""
            }`,
          });
        })
        .catch((err) => {
          const { response = {} } = err;

          // lang.errorSavingRegistrationToastContent
          notification.error({ duration: 7, message: "FAILED", description: response.data });
          setSubmittingForm(false);
        });
    };

    // check if there is unsaved time regs
    if (watch("timeRegistrations").length !== formData.timeRegistrations.length) {
      Modal.confirm({
        title: "Unsaved Time Registration Detected",
        content: "Unsaved time registrations will be discarded. Proceed? ",
        onOk: () => {
          proceedToSubmit();
        },
      });
    } else {
      proceedToSubmit();
    }
  };

  return (
    <Page className={componentStyles()}>
      {console.log("--RENDER--")}
      <ScrollView>
        <div className="content-wrapper">
          <Form layout="vertical" onFinish={handleSubmit}>
            {/* DATE */}
            <p>{lang.timeRegistrationFormDate}</p>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DatePicker
                      value={value}
                      onDateChange={(date) => {
                        onChange(date);
                      }}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* LOCATION */}
            <p>{lang.location}</p>
            <Controller
              name="location"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      loading={dabLocationsIsFetching}
                      onChange={onChange}
                      options={
                        dabLocations
                          ? sortBy(
                              dabLocations.map((d) => ({ label: d.DabLocationValue, value: d.DabLocationValue })),
                              "DabLocationId"
                            )
                          : []
                      }
                      placeholder={lang.chooseADabLocation}
                      value={value}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* PROJECT */}
            <p>{lang.project}</p>
            <Controller
              name="project"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      loading={projectStatus === "loading"}
                      name="project"
                      onChange={(e) => {
                        setValue("subProject", "");
                        setValue("activity", "");

                        //NPT AND HOTT ENABLED
                        const isDetailedReportingEnabled = projectData.filter((d) => d.id === e.target.value)[0]
                          .detailedReportingEnabled;
                        setValue("detailedReportingEnabled", isDetailedReportingEnabled);

                        if (isDetailedReportingEnabled) {
                          notification.info({
                            duration: 3,
                            message: "Detailed Reporting",
                            description: "The selected project has detailed reporting enabled",
                          });
                        }

                        onChange(e);
                      }}
                      options={projectData.map((d) => ({
                        label: `${d.id} - ${d.name}`,
                        value: d.id,
                      }))}
                      placeholder={lang.timeRegistrationFormProjectPlaceholder}
                      value={value}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* SUB-PROJECT */}
            {getValues("project") && (
              <>
                <p>{lang.subproject}</p>
                <Controller
                  name="subProject"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                    return (
                      <>
                        <DropDown
                          loading={subProjectStatus === "loading"}
                          onChange={onChange}
                          options={subProjectData.map((d) => ({ label: `${d.id} - ${d.description}`, value: d.id }))}
                          placeholder={getSubProjectPlaceholder()}
                          value={value}
                        />
                        {error && <h6 className="fieldError">{error.message}</h6>}
                      </>
                    );
                  }}
                />
              </>
            )}

            {/* ACTIVITY */}
            {getValues("project") && getValues("subProject") && (
              <>
                <p>{lang.activity}</p>
                <Controller
                  name="activity"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                    return (
                      <>
                        <DropDown
                          loading={projectActivityStatus === "loading"}
                          name="activity"
                          onChange={onChange}
                          options={projectActivityData.map((d) => ({ label: `${d.no} - ${d.description}`, value: d.id }))}
                          placeholder={getProjectActivityPlaceholder()}
                          value={value}
                        />
                        {error && <h6 className="fieldError">{error.message}</h6>}
                      </>
                    );
                  }}
                />
              </>
            )}

            {/* TRADE */}
            <p>{lang.dabTrade}</p>
            <Controller
              name="trade"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      loading={dabTradeIsFetching || defaultEmployeeValuesStatus === "loading"}
                      name="trade"
                      onChange={onChange}
                      options={
                        tradeCodesList
                          ? tradeCodesList.map((d) => ({
                              label: `${d.DabTradeId} - ${d.DabTradeDesc}`,
                              value: d.DabTradeId,
                            }))
                          : []
                      }
                      placeholder={lang.chooseADabTrade}
                      value={value}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* FOREMAN */}
            <p>{lang.timeRegistrationFormForeman}</p>
            <Controller
              name="foreman"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      // loading={}
                      name="foreman"
                      onChange={onChange}
                      options={foremen.map((d) => ({ disabled: d.disabled, label: d.name, value: d.id }))}
                      placeholder={lang.timeRegistrationFormForemanPlaceholder}
                      value={parseInt(value)}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />
            <div className="seperator"></div>
            {/* TIME REGISTRATION */}
            <Controller
              name="timeRegistrations"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <TimeRegistrationList
                      value={value}
                      onChange={(e) => {
                        // set End Of Rotation to TRUE when selecting Heli Time Home
                        // if true , no localstorage

                        setValue(
                          "endOfRotation",
                          e.some((d) => d.savedByBlueCollar && d.type === 6)
                        );

                        onChange(e);
                      }}
                      location={watch("location")}
                      locationIFSRules={dabLocations}
                      locationIsLoading={dabLocationsIsFetching}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            <div className="actionButtons">
              {!registration && (
                <Button
                  active={submittingForm}
                  style={{ marginTop: "2rem" }}
                  onClick={handleSubmit((e) => onSubmit(e, true))}
                >
                  {lang.timeRegistrationFormSaveAndCopyToNewDay}
                </Button>
              )}

              <Button
                active={submittingForm}
                style={{ marginTop: "2rem" }}
                onClick={handleSubmit((e) => onSubmit(e, false))}
              >
                {!registration ? lang.save : "Update"}
              </Button>
            </div>
          </Form>
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyles = () => css`
  background-color: white;
  padding-top: ${common.topBarHeight}px;

  .content-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }

  .time-input {
    display: flex;
    align-items: center;
  }

  .scroll-view {
    padding: 0.75rem 0.75rem 5rem 0.75rem;
  }

  p {
    padding: 0.75rem 0 0 0;
  }

  h2:not(:first-child) {
    border-top: 1px solid ${colors.lightGrey};
    margin: 0.75rem -0.75rem 0 -0.75rem;
    padding: 0.75rem 0.75rem 0 0.75rem;
  }

  .activity {
    display: flex;
    align-items: center;
    border-top: 1px solid ${colors.lightGrey};
    padding: 0.75rem 0.75rem 0.75rem 0;
    margin: 0 -0.75rem 0 0rem;

    &:last-of-type {
      border-bottom: 1px solid ${colors.lightGrey};
    }

    .name {
      flex: 1 1 70%;
      padding: 0;
    }

    svg {
      height: 40px; /* WARNING: Magic number */
    }

    input {
      flex: 1 1 2rem;
      margin: 0 0.5rem 0 0.5rem;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .note {
    color: rgb(110, 110, 110);
  }

  .fieldError {
    color: red;
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 40px;

    button {
      width: auto !important;
    }
  }
  .seperator {
    background-color: ${colors.lightGrey};
    height: 1px;
    margin: 1.5rem -0.75rem 1rem -0.75rem;
  }
`;

export default memo(WorkTimeRegistration);
