import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import req from "../../../../utilities/request-utility";

function usePunchSingleData(punchId) {
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);

  const [punch, setPunch] = useState({
    data: {},
    loading: true,
    error: false,
  });

  const [punchAllowances, setPunchAllowances] = useState({
    data: {},
    loading: true,
    error: false,
  });

  useEffect(() => {
    getPunch();
    getPunchAllowances();
    // eslint-disable-next-line
  }, []);

  function getPunch() {
    setPunch({ ...punch, loading: true });

    req()(`semcompletion/v2/punches/${punchId}?projectId=${selectedProject.id}`)
      .then(({ data }) =>
        setPunch({
          ...punch,
          loading: false,
          error: false,
          data: data,
        })
      )
      .catch((err) =>
        setPunch({
          ...punch,
          loading: false,
          error: true,
        })
      );
  }

  function getPunchAllowances() {
    setPunch({ ...punch, loading: true });

    req()(`semcompletion/v2/punches/${punchId}/allowances?projectId=${selectedProject.id}`)
      .then(({ data }) =>
        setPunchAllowances({
          ...punch,
          loading: false,
          error: false,
          data: data,
        })
      )
      .catch((err) =>
        setPunchAllowances({
          ...punch,
          loading: false,
          error: true,
        })
      );
  }

  return { punch, punchAllowances, getPunch };
}

export default usePunchSingleData;
