import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { notification } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/da"; // Import the Danish locale
// css
import { css } from "emotion";
import colors from "../../style/colors";
import workHourState from "../../config/semcotimedk-workhour-states";
import {
  CheckIcon,
  DotsVerticalIcon,
  PlusIcon,
  CheckboxMarkedIcon,
  CheckCircleOutlineIcon,
  DeleteEmptyIcon,
  TimerSandEmptyIcon,
  ThumbDownOutlineIcon,
  InformationOutlineIcon,
  PencilIcon,
  TrashOutlineIcon,
  LockOpenOutlineIcon,
  LockIcon,
} from "mdi-react";

import InlineSpinner from "../ui/InlineSpinner";

// actions
import { showContextMenu, showModalPage, showDialog, hideDialog } from "../../actions/uiActions";
// components
import WorkTimeDetailsDK from "./WorkTimeDetailsDK";
import WorkTimeRegistrationDK from "../WorkTimeDK/WorkTimeRegistrationDK";
import WorkTimeRegistrationDK2 from "../WorkTimeDK/WorkTimeRegistrationDK2";

function getTitle(titleString) {
  let [date, startTime, endTime] = titleString.split("__");
  let returnString = date;
  if (startTime && endTime) returnString += `  ${startTime} - ${endTime}`;
  return returnString;
}

function WorkTimeListItemDK(props) {
  const { registration, showContextMenu, lang, loading, title, showModalPage, updateRegistration, updateRegistrationNew } =
    props;
  const selectedLanguage = useSelector((s) => s.auth.user.language);

  const [expanded, setExpanded] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { ifsData = null } = registration;

  function ContextMenu() {
    let options = [
      {
        icon: <InformationOutlineIcon />,
        title: lang.details,
        callback: () =>
          showModalPage({
            content: <WorkTimeDetailsDK title={lang.details} registration={registration} fetchDate={title.split("__")[0]} />,
          }),
      },
    ];

    if (registration.state === workHourState.open) {
      options = [
        ...options,
        {
          icon: <PencilIcon />,
          title: lang.edit,
          callback: () =>
            showModalPage({
              content: registration.isNewType ? (
                <WorkTimeRegistrationDK2
                  title={lang.registerWorkTime}
                  registration={registration}
                  updateRegistration={updateRegistrationNew}
                  fetchDate={title.split("__")[0]}
                />
              ) : (
                <WorkTimeRegistrationDK
                  title={lang.registerWorkTime}
                  registration={registration}
                  updateRegistration={updateRegistration}
                  fetchDate={title.split("__")[0]}
                />
              ),
            }),
        },
        {
          icon: <TrashOutlineIcon />,
          title: lang.delete,
          callback: () =>
            props.showDialog({
              styleType: "warning",
              title: "Delete record",
              content: "You're about to delete this registration. Are you sure you want to delete it?",
              primaryActionTitle: "YES - Delete",
              primaryAction: () => {
                setDeleting("true");

                const request = props.deleteRegistration(registration);

                request
                  .then(() => {
                    notification.success({
                      duration: 7,
                      message: "SUCCESS",
                      description: lang.deletedRegistrationToastContent,
                    });
                  })
                  .catch((err) => {
                    notification.error({
                      duration: 7,
                      message: "FAILED",
                      description: lang.errorDeletingRegistrationToastContent,
                    });
                  });

                setDeleting("false");
              },
            }),
        },
      ];
    }

    return (
      <DotsVerticalIcon
        style={{ width: "1.35rem", height: "1.35rem", margin: "0 0.25rem " }}
        onClick={(e) => {
          e.stopPropagation();
          showContextMenu(options);
        }}
      />
    );
  }

  return (
    <div className={`${componentStyles(props)} ${expanded ? "expanded" : "collapsed"}`}>
      {/* Header */}
      <div
        className="item-header"
        onClick={() =>
          registration.state === workHourState.lockedByForeman ||
          registration.state === workHourState.locked ||
          registration.state === workHourState.approved
            ? setExpanded(!expanded)
            : null
        }
      >
        <div className="left">
          <div style={{ display: "inline-flex" }}>
            {registration.state === workHourState.open && <LockOpenOutlineIcon />}
            {registration.state === workHourState.lockedByForeman && <LockIcon />}
            {registration.state === workHourState.locked && <LockIcon />}
            {registration.state === workHourState.approved && <CheckIcon />}
            {registration.state === workHourState.deleted && <DeleteEmptyIcon style={{ color: "red" }} />}
            {getTitle(title)}
          </div>
          <div style={{ marginLeft: "1.5rem" }}>{moment(title).locale(selectedLanguage).format("dddd")}</div>
        </div>

        <div className="right">
          {/* <div style={{ marginRight: "0.25rem" }} className="workTime">
            {registration.totalHours}
          </div> */}

          {(deleting || loading) && (
            <InlineSpinner size="14" style={{ display: "inline-block", margin: "5px 0.5rem 0rem 0rem" }} />
          )}
          {!deleting && !loading && <ContextMenu />}

          {registration.state === "APPROVED" && (
            <PlusIcon style={{ marginRight: "-0.05rem" }} className={expanded ? "rotate" : "rotate-neutral"} />
          )}
        </div>
      </div>

      {/* Details */}
      <div className={`item-details`}>
        <div className={`${expanded ? "show" : "hide"}`}>
          <div style={{ borderTop: `1px solid ${colors.midGrey}` }}>
            <div className="details-header">
              <div className="left">
                {registration.confirmed === "Yes" && <CheckboxMarkedIcon style={{ color: colors.green }} />}
                {registration.confirmed === "No" && <TimerSandEmptyIcon style={{ color: colors.grey }} />}
                <p>{registration.reportCodeDescription}</p>
              </div>
              <div className="right">
                <p className="hours">{registration.hours}</p>
              </div>
            </div>

            <div className="details">
              {ifsData && (
                <table>
                  <thead>
                    <tr>
                      <th>{lang.wageCode}</th>
                      <th>{lang.hours}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ifsData.map((d) => (
                      <tr key={d.WageCode}>
                        <td>
                          {d.WageCode} - {d.WageCodeDesc}
                        </td>
                        <td>{d.WageHours} hours</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const componentStyles = (props) => css`
  background-color: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;
  margin-bottom: -1px;

  svg {
    color: ${props.primaryColor};
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    margin-top: 2px;
    display: block;
  }

  &.collapsed {
    margin: 0;
    border-left: 3px solid transparent;
    transition: margin 240ms ease;
    margin-bottom: -1px;

    .hide {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 240ms ease, opacity 240ms ease, overflow 240ms ease;
    }

    .rotate-neutral {
      transform: rotate(0deg);
      transition: transform 240ms ease;
    }
  }

  &.expanded {
    margin: 0.65rem 0;
    border-left: 3px solid ${props.primaryColor};
    transition: margin 240ms ease, border-left 240ms ease;

    &:first-of-type {
      margin-top: 0px;
    }

    .item-header {
      color: ${props.primaryColor};

      .rotate {
        transform: rotate(-45deg);
        transition: transform 240ms ease;
      }
    }

    .item-details {
      .show {
        max-height: 1500px;
        overflow: auto;
        opacity: 1;
        transition: max-height 240ms ease, opacity 240ms ease;
      }
    }
  }

  .item-header {
    display: flex;
    padding: 0.75rem;
    margin-bottom: -1px;
    color: ${colors.darkGrey};
    font-weight: bold;

    &:active {
      background-color: ${colors.ultraLightGrey};
      transition: background-color 80ms ease;
    }

    .right {
      display: flex;
      margin-left: auto;
      font-weight: bold;
    }

    .left {
      display: block;
    }
  }

  .item-details {
    .details-header {
      display: flex;
      padding: 0.75rem 0.75rem 0 0.75rem;

      .left {
        display: block;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.5rem;
          flex-shrink: 0;
        }
      }

      .right {
        display: flex;
        margin-left: auto;
        font-weight: bold;

        p.hours {
          color: ${colors.darkGrey};
          margin-right: 0.35rem;
        }
      }
    }

    .details {
      padding: 0.5rem 0.75rem 1rem 0.75rem;
      color: ${colors.darkGrey};

      span {
        font-weight: bold;
      }

      p {
        padding-right: 1rem;
        display: inline-block;
      }

      table {
        tr {
          th {
            text-align: left;
          }
          td {
            text-align: left;
            padding: 5px 20px 5px 0px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 944px) {
    .item-header {
      .left {
        display: inline-flex;
      }

      .left div:last-child {
        margin-left : 0.8rem !important
      } 
  }
`;

const mapStateToProps = (state) => ({
  lang: state.language.language,
  primaryColor: state.appConfig.primaryColor,
});

const mapDispatchToProps = (dispatch) => ({
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkTimeListItemDK);
