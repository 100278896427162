import React, { memo, useState } from "react";
import { Button, Col, Modal, Row, Radio, Space, Typography, Divider } from "antd";
import NotificationImportant from "@mui/icons-material/NotificationImportant";

const CustomMessageModal = () => {
  const [hideMessage, setHideMessage] = useState(false);
  const [showModal, setShowModal] = useState(true);

  return (
    <Modal
      className={"contact-it-support-modal"}
      title={
        <Row style={{ background: "#103447", padding: "0" }}>
          <Col span={24}>
            <Row justify="center">
              <NotificationImportant style={{ fontSize: "50px", color: "white", marginTop: "15px" }} />
            </Row>
          </Col>

          <Col span={24}>
            <Row justify="center">
              <p
                style={{
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                NOTICE
              </p>
            </Row>
          </Col>
        </Row>
      }
      closable={false}
      closeIcon={null}
      visible={showModal}
      footer={
        <Button
          type="primary"
          onClick={() => {
            if (hideMessage) localStorage.setItem(`hideCustomMessageModal2`, "true");

            setShowModal(!showModal);
          }}
        >
          OK
        </Button>
      }
    >
      <Space direction="vertical">
        <Typography.Text>
          Please note - there have been made a change in the app, so that everybody that approves hours in the app, will now
          be able to unlock and correct hours themselves.
        </Typography.Text>

        <Typography.Text strong>
          IMPORTANT : To get access remember to update your app to the latest version 6.3.1
        </Typography.Text>
        <Typography.Text stron italic>
          There is a one-pager on the Semco Maritime App under FAQ & Guides
        </Typography.Text>

        <Divider />
        <Typography.Text>
          Bemærk - der er blevet lavet en ændring i app’en, således at alle der godkender timer i app’en, kan nu selv låse
          timer op og lave rettelser.
        </Typography.Text>

        <Typography.Text strong>
          VIGTIGT : For at få adgang,husk at opdatere din app til seneste version 6.3.1
        </Typography.Text>
        <Typography.Text italic>Der ligger en onepager på Semco Maritime App under FAQ & Guides</Typography.Text>
        <br />

        <Radio
          checked={hideMessage}
          onClick={() => {
            setHideMessage(!hideMessage);
          }}
        >
          Don't show this message again
        </Radio>
      </Space>
    </Modal>
  );
};

export default memo(CustomMessageModal);
