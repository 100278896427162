import React, { useEffect, useLayoutEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ArrowBackIcon, InfoOutlineIcon, PlusIcon, FilePdfIcon, FileExcelIcon, MagnifyIcon } from 'mdi-react';

import { Button, notification } from 'antd';
import { parse, format, startOfMonth, endOfMonth } from 'date-fns';
import { css } from 'emotion';
import { useQuery } from 'react-query';
import param from 'jquery-param';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';

import usePageAndBackButtonURL from '../../../hooks/usePageAndBackButtonURL';
import pageNavigator from '../../../utilities/page-navigator';
import req from '../../../utilities/request-utility';

import colors from '../../../style/colors';
import InlineSpinner from '../../ui/InlineSpinner';
import StatusBox from '../../ui/StatusBox';
import DropDown from '../../ui/DropDown';
import ActionWrapper from '../../ui/ActionWrapper';
import ComponentPage from '../../ui/Page';
import TopBar from '../../ui/TopBar';
import PDFRenderComponent from '../../ui/PDFRenderComponent';

function WorkTimeNptHottReportDK(props) {
	const { user = {} } = props;
	const { id: userAppId = '', loggedUserTypeDK = 'user' } = user;

	const { page, backButtonURL } = usePageAndBackButtonURL(props);
	const [excelData, setExcelData] = useState([]);
	const [tableData, setTableData] = useState({
		pdfFile: '',
		error: false,
		loading: false,
		empty: false
	});

	const exportAndGenerateTemplate = async () => {
		const workbook = new ExcelJS.Workbook();
		const sheet = workbook.addWorksheet('table');

		sheet.columns = [
			{ header: 'Employee.', key: 'employee', width: 30 },
			{ header: 'Ticket No.', key: 'ticketNo', width: 30 },
			{ header: 'Project', key: 'project', width: 30 },
			{ header: 'Date', key: 'date', width: 30 },
			{ header: 'NPT Activity', key: 'nptActivity', width: 20 },
			{ header: 'NPT Description', key: 'nptDescription', width: 50 },
			{ header: 'NPT Hours', key: 'nptHours', width: 15 },
			{ header: 'HOTT Work Order No.', key: 'hottWorkOrderNo', width: 20 },
			{ header: 'HOTT Operation No.', key: 'hottOperationNo', width: 50 },
			{ header: 'HOTT Description', key: 'hottDescription', width: 50 },
			{ header: 'HOTT Hours', key: 'hottHours', width: 15 }
		];

		const headerRange = sheet.getRow(1);
		headerRange.eachCell(cell => {
			cell.border = {
				top: { style: 'medium' },
				left: { style: 'medium' },
				bottom: { style: 'medium' },
				right: { style: 'medium' }
			};
		});

		if (excelData.length === 0)
			return notification.error({
				duration: 1,
				message: 'FAILED',
				description: 'Empty, no data to show'
			});

		excelData.forEach(rowData => {
			sheet.addRow(rowData);
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const blobData = new Blob([buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});

		onDownload(blobData);
	};

	const { data: { data: projectData = [] } = {}, isFetching: projectIsFetching } = useQuery(
		'project',
		() => req()(`semcotime/projects`),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			staleTime: 60 * 60 * 1000
		}
	);

	const { data: { data: employeesData = [] } = {}, isFetching: employeesDataIsFetching } = useQuery(
		['GetForemanTeamMembers'],
		() => {
			if (loggedUserTypeDK === 'admin') return req()(`semcotime/foremen-teams/my-team/all`);

			return req()(`semcotime/foremen-teams/my-team`);
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			staleTime: 30 * 1000
		}
	);

	const schema = yup.object().shape({
		fromDate: yup.string().required('From date is required'),
		projectNumber: yup.array().min(1, 'Project Number is required'),
		toDate: yup.string().required('To date is required')
	});

	const defaultValues = {
		employees: [],
		fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
		projectNumber: [],
		toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd')
	};

	const { control, handleSubmit, getValues } = useForm({
		defaultValues,
		resolver: yupResolver(schema)
	});

	const onSubmit = async formData => {
		try {
			const { employees = [], fromDate = '', projectNumber = [], toDate = '' } = formData;

			//to separate the userId and externalId , to reduce extra fetching for user DB
			const employeeIds = employees.map(d => ({ userId: d.split(';')[0], externalId: d.split(';')[1] }));

			setTableData({ pdfFile: '', loading: true, error: false, empty: false });

			const response = await req()(
				`semcotime/reports/nptHottReport?${param({
					employeeIds,
					fromDate,
					projectNumber,
					toDate
				})}`,
				{
					responseType: 'json'
				}
			);

			const pdfBuffer = Buffer.from(response.data.pdfBuffer.data);

			// Create a Blob from the Buffer data
			const receivedBlob = new Blob([pdfBuffer], { type: 'application/pdf' });

			setExcelData(response.data.foremattedRegistrations);
			setTableData({
				loading: false,
				pdfFile: window.URL.createObjectURL(receivedBlob),
				error: false,
				empty: false
			});
		} catch (err) {
			const { status } = err.response;
			if (status === 404) return setTableData({ loading: false, pdfFile: '', error: false, empty: true });

			setTableData({ loading: false, pdfFile: '', error: true, empty: false });
		}
	};

	const onDownload = fileData => {
		const { employees = [], fromDate = '', toDate = '' } = getValues();

		const employeeIds = employees.map(d => d.split(';')[1]);

		FileSaver.saveAs(fileData, `NPT & HOTT Report ${employeeIds.join(' ')} ${fromDate} to ${toDate}`);
	};

	return (
		<ComponentPage className={componentStyles(loggedUserTypeDK === 'user' ? true : false)}>
			<TopBar
				title={page.title}
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
			/>
			<div className="date-selector-bar">
				<div className="dropdown-fields">
					<Controller
						name="projectNumber"
						control={control}
						render={({ field: { onChange, value } }) => {
							return (
								<DropDown
									name="projectNumber"
									placeholder="Project Number(s)"
									onChange={onChange}
									loading={projectIsFetching}
									multiple={true}
									options={projectData
										.filter(p => p.detailedReportingEnabled === 'YES')
										.map(p => ({ label: p.id, value: p.id }))}
									value={value}
								/>
							);
						}}
					/>
					<Controller
						name="employees"
						control={control}
						render={({ field: { onChange, value } }) => {
							return (
								<DropDown
									mode="tags"
									name="employees"
									placeholder="Employee(s)"
									onChange={onChange}
									loading={employeesDataIsFetching}
									multiple={true}
									options={employeesData.map(p => ({
										label: p.name,
										value: `${p.id};${p.externalId}`
									}))}
									value={value}
								/>
							);
						}}
					/>
				</div>
				<div className="right">
					<div className="date-inputs">
						<p>From: </p>
						<Controller
							name="fromDate"
							control={control}
							render={({ field: { onChange, value } }) => {
								return <input value={value} type="date" name="fromDate" onChange={e => onChange(e)} />;
							}}
						/>
						<p>To: </p>
						<Controller
							name="toDate"
							control={control}
							render={({ field: { onChange, value } }) => {
								return <input value={value} type="date" name="toDate" onChange={e => onChange(e)} />;
							}}
						/>
					</div>
					<div className="buttons">
						<Button
							disabled={tableData.loading}
							onClick={handleSubmit(
								e => onSubmit(e),
								e => {
									notification.error({
										duration: 1,
										message: 'FAILED',
										description: Object.keys(e).map(d => e[d].message)[0]
									});
								}
							)}>
							<MagnifyIcon />
						</Button>
						<Button
							onClick={() => onDownload(tableData.pdfFile)}
							disabled={!tableData.pdfFile || tableData.loading}>
							<FilePdfIcon />
						</Button>
						<Button onClick={exportAndGenerateTemplate} disabled={!tableData.pdfFile || tableData.loading}>
							<FileExcelIcon />
						</Button>
					</div>
				</div>
			</div>
			{tableData.loading && <InlineSpinner style={{ marginTop: '2rem' }} title="Loading timesheet..." />}

			{!tableData.loading && tableData.empty && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="No results"
					content="No results found. Try with different dates"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			{!tableData.loading && tableData.error && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="Something went wrong"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			{!tableData.loading && !tableData.error && tableData.pdfFile && (
				<div className="inline-scroll-wrapper" id="custom-scroll-wrapper-kasd872jhKsd72">
					<PDFRenderComponent pdfData={tableData.pdfFile} />
				</div>
			)}
		</ComponentPage>
	);
}

const componentStyles = isUser => css`
	.inline-scroll-wrapper {
		overflow-x: auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.react-pdf__Page__canvas {
			margin: 0 auto !important;
		}
	}

	.date-selector-bar {
		border-bottom: 1px ${colors.midGrey} solid;
		background-color: ${colors.white};
		display: flex;
		align-items: center;
		justify-content: ${isUser ? 'end' : 'space-between'};
		font-size: 1rem;
		padding: 15px 15px 15px 15px;

		.dropdown-fields {
			width: 50%;
			display: flex;
			justify-content: space-evenly;
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.date-inputs {
			display: contents;
		}

		input {
			margin: 0 0.5rem;
			color: ${colors.darkGrey};
			border: 1px ${colors.darkGrey} dashed;
			border-width: 0 0 1px 0;
			font-size: 1rem;
		}
	}

	@media screen and (max-width: 944px) {
		.date-selector-bar {
			display: flex;
			flex-direction: column;
			height: initial;
			gap: 15px;

			.dropdown-fields {
				display: block;
				margin-top: 10px;
				width: 100%;
			}

			.right {
				display: block;

				.date-inputs {
					display: inline-flex;
				}

				.buttons {
					margin-top: 10px;
					display: flex;
					justify-content: center;
					margin-bottom: 10px;
				}
			}
		}
	}
`;

export default WorkTimeNptHottReportDK;
