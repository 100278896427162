import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useQuery } from "react-query";

import req from "../../../../utilities/request-utility";

const LIMIT = 40;
let statusCountTimeout;

function useOverviewData() {
  const dispatch = useDispatch();

  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);

  // META DATA
  const {
    isLoading: metaDataIsLoading,
    error: metaDataError,
    data: { data: metaDataData = {} } = {},
  } = useQuery(
    `${selectedProject ? selectedProject.id : "project"}-punch-metaData`,
    () => req()(`semcompletion/v2/punches/meta-data?projectId=${selectedProject.id}`),
    {
      staleTime: 60 * 60 * 1000, //1 hour
    }
  );
  const metaData = { data: metaDataData, loading: metaDataIsLoading, error: metaDataError };

  // Data states
  const [openPunches, setOpenPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });
  const [clearedPunches, setClearedPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });
  const [closedPunches, setClosedPunches] = useState({
    data: [],
    loading: false,
    error: false,
    endOfFeed: false,
  });
  const [statusCounts, setStatusCounts] = useState({
    data: {},
    loading: true,
    error: false,
  });

  function getStatusCounts({ filterData = {}, setAsLoading = false }) {
    if (setAsLoading) setStatusCounts({ ...statusCounts, loading: true });

    clearTimeout(statusCountTimeout);
    statusCountTimeout = setTimeout(() => {
      req()(`semcompletion/v2/punches/status-count?projectId=${selectedProject.id}&punchList=${filterData.punchList || ""}`)
        .then(({ data }) => setStatusCounts({ data, loading: false, error: false }))
        .catch(() => dispatch({ template: "error", content: "Could not get task punch count" }));
    }, 700);
  }

  function getRefreshDataLenght(number) {
    if (number < LIMIT) {
      return LIMIT;
    } else {
      return number;
    }
  }

  /**
   * @param {Object} options
   * @param {Number} options.tabIndex - The index of the for which to get data
   * @param {Number} options.refreshData - If set to true, the already loaded data will be replaced with new data
   */
  const getData = ({ tabIndex, refreshData = false, filterData, clearDataBeforeFetch = false }) => {
    let baseURL = `semcompletion/v2/punches?projectId=${selectedProject.id}`;

    // Add query params to list
    if (filterData.punchList) baseURL += `&punchList=${filterData.punchList}`;
    if (filterData.punchOwner) baseURL += `&punchOwner=${filterData.punchOwner}`;
    if (filterData.punchOrigin) baseURL += `&punchOrigin=${filterData.punchOrigin}`;
    if (filterData.punchCategory) baseURL += `&punchCategory=${filterData.punchCategory}`;
    if (filterData.punchLocation) baseURL += `&punchLocation=${filterData.punchLocation}`;
    if (filterData.system) baseURL += `&system=${filterData.system}`;
    if (filterData.searchterm) baseURL += `&searchterm=${filterData.searchterm}`;

    // Open
    if (tabIndex === 0) {
      if (refreshData === false && (openPunches.loading || openPunches.endOfFeed)) return;

      setOpenPunches({
        ...openPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : openPunches.data,
      });

      let URL = baseURL;
      URL += "&status=Open";
      URL += `&limit=${refreshData ? getRefreshDataLenght(openPunches.data.length) : LIMIT}`;
      URL += `&offset=${refreshData ? 0 : openPunches.data.length}`;

      req()(URL)
        .then(({ data }) =>
          setOpenPunches({
            data: [...(refreshData ? [] : openPunches.data), ...data],
            loading: false,
            error: false,
            endOfFeed: data.length < LIMIT ? true : false,
          })
        )
        .catch((err) => {
          dispatch({ template: "error", content: "Failed to get open punches" });
          setOpenPunches({
            ...openPunches,
            error: true,
            loading: false,
          });
        });
    }

    // Cleared
    if (tabIndex === 1) {
      if (refreshData === false && (clearedPunches.loading || clearedPunches.endOfFeed)) return;

      setClearedPunches({
        ...clearedPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : clearedPunches.data,
      });

      let URL = baseURL;
      URL += "&status=Cleared";
      URL += `&limit=${refreshData ? getRefreshDataLenght(clearedPunches.data.length) : LIMIT}`;
      URL += `&offset=${refreshData ? 0 : clearedPunches.data.length}`;

      req()(URL)
        .then(({ data }) =>
          setClearedPunches({
            data: [...(refreshData ? [] : clearedPunches.data), ...data],
            loading: false,
            error: false,
            endOfFeed: data.length < LIMIT ? true : false,
          })
        )
        .catch((err) => {
          dispatch({ template: "error", content: "Failed to get cleared punches" });
          setClearedPunches({
            ...clearedPunches,
            error: true,
            loading: false,
          });
        });
    }

    // Cleared
    if (tabIndex === 2) {
      if (refreshData === false && (closedPunches.loading || closedPunches.endOfFeed)) return;

      setClosedPunches({
        ...closedPunches,
        loading: true,
        error: false,
        data: clearDataBeforeFetch ? [] : closedPunches.data,
      });

      let URL = baseURL;
      URL += "&status=Closed";
      URL += `&limit=${refreshData ? getRefreshDataLenght(closedPunches.data.length) : LIMIT}`;
      URL += `&offset=${refreshData ? 0 : closedPunches.data.length}`;

      req()(URL)
        .then(({ data }) =>
          setClosedPunches({
            data: [...(refreshData ? [] : closedPunches.data), ...data],
            loading: false,
            error: false,
            endOfFeed: data.length < LIMIT ? true : false,
          })
        )
        .catch((err) => {
          dispatch({ template: "error", content: "Failed to get closed punches" });
          setClosedPunches({
            ...closedPunches,
            error: true,
            loading: false,
          });
        });
    }
  };

  return { openPunches, clearedPunches, closedPunches, statusCounts, getStatusCounts, metaData, getData };
}

export default useOverviewData;
