import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon } from "mdi-react";
import { css } from "emotion";
import { isEmpty, isEqual } from "lodash";
import { format, isAfter, parse } from "date-fns";
import param from "jquery-param";
import { Modal, Popconfirm } from "antd";
import { useMediaQuery } from "react-responsive";

import workHourState from "../../../config/semcotimedk-workhour-states";

import ScrollView from "../../ui/ScrollView";
import InlineSpinner from "../../ui/InlineSpinner";
import DropDown from "../../ui/DropDown";
import DatePicker from "../../ui/DatePicker";
import TextInput from "../../ui/TextInput";
import CheckboxGroup from "../../ui/CheckboxGroup";
import TextareaInput from "../../ui/TextareaInput";
import Button from "../../ui/Button";

import req from "../../../utilities/request-utility";

import common from "../../../style/common";
import colors from "../../../style/colors";

import { toggleWorkHourModal } from "../../../actions/uiActions";

const ForemanAbsenceForm = (props, ref) => {
  const {
    blueCollarExternalId = "",
    data: registration = {},
    onDelete: onDeleteParent,
    onSubmit: onSubmitParent,
    lang,
    page = "foreman",
  } = props;
  const { data = {}, ifsData = null } = registration;
  const dispatch = useDispatch();

  const formDataInitialValues = {
    absenceCode: registration.data ? registration.data.absenceCode : undefined,
    startDate: registration.data
      ? format(parse(registration.data.startDate, "yyyyMMdd", 0), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    startTimeMinutes: registration.data ? registration.data.startTime.split(":")[1] : "",
    startTimeHours: registration.data ? registration.data.startTime.split(":")[0] : "",
    endDate: registration.data
      ? format(parse(registration.data.endDate, "yyyyMMdd", 0), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    endTimeMinutes: registration.data ? registration.data.endTime.split(":")[1] : "",
    endTimeHours: registration.data ? registration.data.endTime.split(":")[0] : "",
    note: registration.data ? registration.data.note : "",
    fullDay: registration.data ? registration.data.fullDay : false,
    foreman: registration.data ? registration.data.foreman : "",
  };

  const [isFormReady, setIsFormReady] = useState(false);
  const [absenceCodes, setAbsenceCodes] = useState([]);
  const [formData, setFormData] = useState(formDataInitialValues);
  const [formDataOrig, setFormDataOrig] = useState(formDataInitialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [foremen, setForemen] = useState([]);

  const { primaryColor } = useSelector((s) => s.appConfig.primaryColor, isEqual);
  const modal = useSelector(({ ui }) => ui.workHourModal);

  const fetchAbsenceCodes = async () => {
    try {
      const { data } = await req()(`semcotime/absence-dk/absence-codes-dk/${blueCollarExternalId}`);
      setAbsenceCodes(data);
      setIsFormReady(true);
    } catch (error) {
      console.log("error: ", error);
      setIsFormReady(true);
    }
  };

  const getForemen = () => {
    try {
      setIsFormReady(false);

      req()(`semcotime/foremen?${param({ foremanGroupIdentifier: page === "admin" ? "ADMIN" : "FOREMEN" })}`).then(
        ({ data }) => {
          const assignedIds = data.assigned.map((d) => d.id);

          setForemen([
            ...data.assigned,
            { disabled: true, id: "", name: "------------------" },
            ...data.all.filter((d) => !assignedIds.includes(d.id)),
          ]);
        }
      );

      setIsFormReady(true);
    } catch (err) {
      // setErrorLoadingFormData(true);
    }
  };

  const handleFormChange = (e, name = null) => {
    if (!name) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [name]: e.target.value });
    }
  };

  const getStartAndEndTimeAndDate = () => {
    const startDate = format(parse(formData.startDate, "yyyy-MM-dd", 0), "yyyyMMdd");
    const endDate = format(parse(formData.endDate, "yyyy-MM-dd", 0), "yyyyMMdd");
    return { startDate, endDate };
  };

  const getTime = (date, hours, minutes) =>
    !formData.fullDay && hours !== "" && minutes !== ""
      ? format(parse(`${date} ${hours}:${minutes}`, "yyyyMMdd HH:mm", 0), "yyyyMMdd HH:mm").split(" ")[1]
      : "";

  const handleSubmit = async (form) => {
    setIsSubmitting(true);

    const { startDate, endDate } = getStartAndEndTimeAndDate();

    let registrationForm = {
      startDate,
      startTime: getTime(startDate, form.startTimeHours, form.startTimeMinutes),
      endDate,
      endTime: getTime(endDate, form.endTimeHours, form.endTimeMinutes),
      note: form.note,
      absenceCode: form.absenceCode,
      fullDay: form.fullDay,
      type: "absence",
      FullTimeAppUser: data.FullTimeAppUser,
    };

    if (registration.id) registrationForm.id = registration.id;

    await onSubmitParent(registrationForm);
    setIsSubmitting(false);
  };

  const onDelete = async () => {
    setIsDeleting(true);
    await onDeleteParent();
    setIsDeleting(false);
  };

  const archived = useMemo(() => (registration.lockedByIFS ? true : false), [registration.lockedByIFS]);
  const lockedByForeman = useMemo(() => (registration.lockedByForeman ? true : false), [registration.lockedByForeman]);

  useImperativeHandle(ref, () => ({
    isFormDataChanged: () => (!isEqual(formDataOrig, formData) ? true : false),
  }));

  useEffect(() => {
    getForemen();
    fetchAbsenceCodes(); // eslint-disable-next-line
  }, [blueCollarExternalId]);

  useEffect(() => {
    setFormData(formDataInitialValues);
    setFormDataOrig(formDataInitialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(registration)]);

  useEffect(() => {
    if (formData.startDate !== "" && formData.endDate !== "") {
      if (isAfter(new Date(formData.startDate), new Date(formData.endDate)))
        setFormData({ ...formData, endDate: formData.startDate });
    } // eslint-disable-next-line
  }, [formData.startDate, formData.endDate]);

  const renderForm = () => {
    return (
      <>
        <ScrollView
          className={scrollViewStyles({
            topBarHeight: common.topBarHeight,
            bottomPanelHeight: archived ? 0 : 66,
          })}
        >
          <div className={componentStyles(primaryColor)}>
            {!isFormReady && (
              <div className="meta">
                <InlineSpinner title={lang.loadingForm} />
              </div>
            )}

            {isFormReady && registration.state === workHourState.deleted && <div className="deleted">DELETED IN IFS</div>}

            {isFormReady && (
              <>
                <div className="input-wrapper">
                  <label htmlFor="">{lang.absenceRegistrationFormAbsenceCode}</label>
                  <DropDown
                    disabled={false}
                    name="absenceCode"
                    onChange={handleFormChange}
                    options={absenceCodes.map((d) => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
                    placeholder={lang.absenceRegistrationFormAbsenceCodePlaceholder}
                    value={formData.absenceCode}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">{lang.startDate}</label>
                  <DatePicker
                    disabled={false}
                    name="startDate"
                    className="input"
                    defaultDate={formData.startDate}
                    onDateChange={(date) => handleFormChange({ target: { value: date } }, "startDate")}
                  />
                </div>
                {!formData.fullDay && (
                  <div className="input-wrapper">
                    <label htmlFor="">{lang.absenceRegistrationFormStartTime}</label>
                    <div className="time-input">
                      <TextInput
                        disabled={false}
                        min={0}
                        max={59}
                        name="startTimeHours"
                        onChange={handleFormChange}
                        type="number"
                        value={formData.startTimeHours}
                      />
                      <p style={{ padding: "0 0.5rem" }}>{` : `}</p>
                      <TextInput
                        disabled={false}
                        min={0}
                        max={59}
                        name="startTimeMinutes"
                        onChange={handleFormChange}
                        type="number"
                        value={formData.startTimeMinutes}
                      />
                    </div>
                  </div>
                )}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.endDate}</label>
                  <DatePicker
                    disabled={false}
                    name="endDate"
                    className="input"
                    defaultDate={formData.endDate}
                    onDateChange={(date) => handleFormChange({ target: { value: date } }, "endDate")}
                  />
                </div>
                {!formData.fullDay && (
                  <div className="input-wrapper">
                    <label htmlFor="">{lang.absenceRegistrationFormEndTime}</label>
                    <div className="time-input">
                      <TextInput
                        disabled={false}
                        min={0}
                        max={59}
                        name="endTimeHours"
                        onChange={handleFormChange}
                        type="number"
                        value={formData.endTimeHours}
                      />{" "}
                      <p style={{ padding: "0 0.5rem" }}>{` : `}</p>
                      <TextInput
                        disabled={false}
                        min={0}
                        max={59}
                        name="endTimeMinutes"
                        onChange={handleFormChange}
                        type="number"
                        value={formData.endTimeMinutes}
                      />
                    </div>{" "}
                  </div>
                )}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.absenceRegistrationFormFullDay}</label>
                  <CheckboxGroup
                    disabled={false}
                    onSelect={(e) => handleFormChange({ target: { value: e.length !== 0 ? true : false } }, "fullDay")}
                    options={[{ id: "fullDay", title: "" }]}
                    selected={formData.fullDay ? ["fullDay"] : []}
                    style={{ border: "none", width: "100%" }}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">Note</label>
                  <TextareaInput
                    disabled={false}
                    name="note"
                    value={formData.note}
                    placeholder={lang.enterNote}
                    onChange={handleFormChange}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">{lang.absenceRegistrationFormForeman}</label>
                  <DropDown
                    disabled={false}
                    name="foreman"
                    className="input"
                    onChange={handleFormChange}
                    options={foremen.map((d) => ({
                      disabled: d.disabled,
                      label: d.name,
                      value: d.id,
                    }))}
                    placeholder={lang.absenceRegistrationFormForemanPlaceholder}
                    value={formData.foreman}
                  />
                </div>
              </>
            )}
          </div>
          {isFormReady && ifsData && (
            <div className={componentStyles(primaryColor)}>
              {ifsData.map((d, i) => (
                <div className="input-wrapper" key={d.WageCode}>
                  <label htmlFor="">{i === 0 ? lang.wageCode : ""}</label>
                  <div className="time-input">
                    <p>
                      {d.WageCode} - {d.WageCodeDesc}
                    </p>
                    <p style={{ padding: "0 0.5rem" }}>{` - `}</p>
                    {d.WageHours} hours
                  </div>
                </div>
              ))}
            </div>
          )}
        </ScrollView>
        {!isMobile && isFormReady && !archived && (
          <div className={footerButtonStyles()}>
            <div className="col-75 checkboxContainer">
              {!!data.id && (
                <CheckboxGroup
                  options={[{ id: "manualApproval", title: lang.requiresManualApproval }]}
                  onSelect={(e) => handleFormChange({ target: { name: "manualApproval", value: e } })}
                />
              )}
            </div>
            <div className="col-25 buttonContainer">
              <Button disabled={isSubmitting} onClick={() => handleSubmit(formData)}>
                {isSubmitting && (
                  <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                )}
                {lang.approved}
              </Button>
              {!isEmpty(registration) &&
                !archived && ( //dont show if create or approved in IFS
                  <Popconfirm
                    disabled={isSubmitting}
                    title={lang.confirmText}
                    onConfirm={onDelete}
                    okText={lang.confirmYes}
                    cancelText={lang.confirmNo}
                  >
                    <Button disabled={isSubmitting} styleType="error">
                      {isDeleting ? (
                        <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </Button>
                  </Popconfirm>
                )}
            </div>
          </div>
        )}
      </>
    );
  };
  const isMobile = useMediaQuery({ maxWidth: "800px" });
  const close = () => {
    dispatch(toggleWorkHourModal(false));
  };
  return (
    <>
      {!isMobile && renderForm()}

      {isMobile && (
        <Modal
          centered
          footer={
            <div className={footerButtonStyles()}>
              <div className="col-75 checkboxContainer">
                {!!data.id && (
                  <CheckboxGroup
                    options={[{ id: "manualApproval", title: lang.requiresManualApproval }]}
                    onSelect={(e) => handleFormChange({ target: { name: "manualApproval", value: e } })}
                  />
                )}
              </div>
              <div className="col-25">
                <Button disabled={isSubmitting} onClick={() => handleSubmit(formData)}>
                  {isSubmitting && (
                    <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                  )}
                  {lang.approved}
                </Button>
                {!isEmpty(registration) && (
                  <Popconfirm
                    disabled={isSubmitting}
                    title={lang.confirmText}
                    onConfirm={onDelete}
                    okText={lang.confirmYes}
                    cancelText={lang.confirmNo}
                  >
                    <Button disabled={isSubmitting} styleType="error">
                      {isDeleting ? (
                        <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          }
          onCancel={close}
          visible={modal}
        >
          {renderForm()}
        </Modal>
      )}
    </>
  );
};

const scrollViewStyles = ({ bottomPanelHeight, topBarHeight }) => css`
  height: calc(100vh - ${topBarHeight}px - ${bottomPanelHeight}px);
  padding: 1rem;

  @media (max-width: 414px) {
    max-height: min-content;
    padding: unset;
  }
`;

const componentStyles = (primaryColor) => css`
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px ${colors.midGrey} solid;
  border-left: 3px ${primaryColor} solid;
  padding: 0.65rem;
  margin-bottom: 10px;

  .deleted {
    width: 100%;
    text-align: center;
    background-color: red;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-of-type  {
      margin-bottom: 0;
    }

    & > label {
      width: 30%;
      text-align: right;
      padding-right: 0.65rem;
      font-size: 0.9rem;
      color: ${colors.darkGrey};
    }

    .input {
      width: 100%;
    }

    .time-input {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .time-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .time-input-wrapper {
      width: 49%;
    }
  }
  @media (max-width: 768px) {
    border-left: 1px ${colors.midGrey} solid;
  }

  @media (max-width: 414px) {
    border: none;
    padding: unset;
    margin-bottom: unset;

    .input-wrapper {
      align-items: flex-start;
      flex-direction: column;

      & > label {
        display: block;
        width: unset;
      }
    }
  }
`;

const footerButtonStyles = () => css`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100%);
  background-color: #f8f8f8;
  padding: 10px 10px 10px 0px;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  display: flex;
  border-top: 1px #103447 solid;

  .checkboxContainer {
    div {
      border: none;

      .checkbox {
        white-space: nowrap;
      }
    }
  }

  .buttonContainer {
    display: flex;
    .css-1imkzsr.neutral {
      margin-right: 7px;
    }
  }

  .col-25 {
    flex-basis: 25%;
  }
  .col-75 {
    flex-basis: 75%;
  }

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;

    .col-25,
    .col-75 {
      flex-basis: 100%;

      &:nth-child(odd) {
        margin-bottom: 1.25rem;
      }
    }
  }

  @media (max-width: 768px) {
    position: unset;
  }

  @media (max-width: 414px) {
    position: unset;
    padding: unset;
    background-color: unset;
    border: unset;
  }
`;

export default memo(forwardRef(ForemanAbsenceForm));
