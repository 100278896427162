import React, { memo, useState } from "react";
import { Button, Col, Divider, Form, Modal, Row, notification } from "antd";
import { Fab, TextField } from "@mui/material";
import InputFile from "@volenday/input-file";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useMediaQuery } from "react-responsive";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import req from "../../utilities/request-utility";

const ContactItSupport = ({ user = {} }) => {
  const { name: userName = "" } = user;

  const isMobile = useMediaQuery({ maxWidth: "800px" });
  const [showModal, setShowModal] = useState(false);
  const [showWriteToUs, setShowWriteToUs] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    Issue: yup.string().required("This field is required"),
    Description: yup.string().required("Please describe the issue"),
  });

  const defaultValues = {
    Attachments: [],
    Description: "",
    Issue: "",
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (form) => {
    setIsSubmitting(true);
    try {
      const { Attachments = [], Description, Issue } = form;
      const body = new FormData();
      Attachments && Attachments.length !== 0 && Attachments.forEach((img) => body.append("attachments", img));
      const data = {
        AttachmentRecordEntity: "SemcoTimeDkEmailAttachments",
        AttachmentRecordField: "Attachments",
        saveRecord: true,
        slug: "contact-it-support",
        Subject: `Semcotime App Support - ${userName} - ${Issue}`,
        recipients: ["itservice@semcomaritime.com"],
        values: { Description, Issue, Employee: userName },
      };
      const parsedData = JSON.stringify(data);
      body.append("form", parsedData);

      await req().post(`AHAMAtic/sendEmail`, body, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      notification.success({
        duration: 7,
        message: "Success",
        description: `Successfully sent message`,
      });
      setIsSubmitting(false);
      reset(defaultValues);
      setShowWriteToUs(false);
    } catch (error) {
      console.log("error: ", error);
      setIsSubmitting(false);
      notification.error({
        duration: 7,
        message: "FAILED",
        description: `Failed to send message: ${error}`,
      });
    }
  };

  const contactInfoComponent = () => (
    <>
      <Row style={{ paddingTop: "10px" }}>
        <Col span={24}>
          <Row justify="center">
            <MailOutlined style={{ fontSize: "25px" }} />
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginBottom: "0px",
              }}
            >
              Email
            </p>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <a href={`mailto:itservice@semcomaritime.com`}>ITService@semcomaritime.com</a>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24}>
          <Row justify="center">
            <PhoneOutlined style={{ fontSize: "25px" }} />
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginBottom: "0px",
              }}
            >
              Phone Number
            </p>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <a href={`tel:+4579166515`}>+45 7916 6515</a>
          </Row>
        </Col>
      </Row>

      <Col span={24}>
        <Row justify="end">
          <Button
            type="primary"
            onClick={() => {
              setShowWriteToUs(true);
            }}
          >
            Write to us
          </Button>
        </Row>
      </Col>
    </>
  );

  const composeEmailComponent = () => (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Controller
        name="Issue"
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
          return (
            <>
              <TextField
                name="Issue"
                size="large"
                fullWidth
                label="What is going on?"
                value={value}
                onChange={onChange}
                variant="outlined"
              />
              {error && <h6 className="fieldError">{error.message}</h6>}
            </>
          );
        }}
      />
      <br />
      <br />
      <Controller
        name="Description"
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
          return (
            <>
              <TextField
                name="Description"
                multiline
                fullWidth
                rows={4}
                label="Tell us what happened.."
                value={value}
                onChange={onChange}
                variant="outlined"
              />
              {error && <h6 className="fieldError">{error.message}</h6>}
            </>
          );
        }}
      />
      <br />
      <br />
      <Controller
        name="Attachments"
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
          return (
            <InputFile
              accept={"image/*"}
              id={"Attachments"}
              label={"Attachments"}
              multiple={true}
              value={value}
              onChange={(d) => {
                onChange(d.target.value);
              }}
            />
          );
        }}
      />
      <Divider />

      <Col span={24}>
        <Row justify="space-between">
          <Button
            type="danger"
            disabled={isSubmitting}
            onClick={() => {
              reset(defaultValues);
              setShowWriteToUs(false);
            }}
          >
            Cancel
          </Button>
          <Button type="primary" loading={isSubmitting} onClick={handleSubmit((e) => onSubmit(e))}>
            Send
          </Button>
        </Row>
      </Col>
    </Form>
  );

  return (
    <div className="contactSupport">
      <Fab
        disabled={isSubmitting}
        onClick={() => setShowModal(!showModal)}
        sx={{
          position: "absolute",
          ...(isMobile ? { top: 60 } : { bottom: 60 }),
          right: isMobile ? 10 : 60,
          color: "common.white",
          bgcolor: "#103447",
          opacity: isMobile ? 0.5 : 1,
        }}
        size={isMobile ? "small" : "default"}
        aria-label={"Add"}
        color={"primary"}
      >
        {<SupportAgentIcon />}
      </Fab>

      <Modal
        className={"contact-it-support-modal"}
        title={
          <Row style={{ background: "#103447", padding: "0" }}>
            <Col span={24}>
              <Row justify="center">
                <SupportAgentIcon style={{ fontSize: "50px", color: "white", marginTop: "15px" }} />
              </Row>
            </Col>

            <Col span={24}>
              <Row justify="center">
                <p
                  style={{
                    color: "white",
                    fontSize: "24px",
                    fontWeight: "600",
                    marginBottom: "15px",
                  }}
                >
                  Contact IT Support
                </p>
              </Row>
            </Col>
          </Row>
        }
        closable={false}
        closeIcon={null}
        visible={showModal}
        footer={
          <Button disabled={isSubmitting} onClick={() => setShowModal(false)}>
            Close
          </Button>
        }
      >
        {!showWriteToUs && contactInfoComponent()}
        {showWriteToUs && composeEmailComponent()}
      </Modal>
    </div>
  );
};

export default memo(ContactItSupport);
