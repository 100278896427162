import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";
import { useQuery } from "react-query";

import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import TopBar from "../ui/TopBar";
import ActionWrapper from "../ui/ActionWrapper";
import { ArrowLeftIcon } from "mdi-react";
import DropDown from "../ui/DropDown";

import usePageAndBackButtonURL from "../../hooks/usePageAndBackButtonURL";
import pageNavigator from "../../utilities/page-navigator";
import req from "../../utilities/request-utility";
import colors from "../../style/colors";
import { SC_SET_SELECTED_PROJECT } from "../../actions/actionTypes";

function SemcompltionProjectChooser(props) {
  const { page, backButtonURL } = usePageAndBackButtonURL(props);
  const { selectedProject } = useSelector((s) => s.semcompletion);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const dispatch = useDispatch();

  const { data: { data: projects = [] } = {}, isLoading } = useQuery("Projects", () => req()("semcompletion/projects/"));

  function changeDefaultProject(projectId) {
    let [newProject] = projects.filter((p) => p.id === projectId);
    dispatch({
      type: SC_SET_SELECTED_PROJECT,
      payload: newProject,
    });
    req().put(`semcompletion/projects/selected/${newProject.id}`);
  }

  return (
    <Page>
      <TopBar
        actionLeft={
          <ActionWrapper onClick={() => pageNavigator(backButtonURL, "backward")}>
            <ArrowLeftIcon />
          </ActionWrapper>
        }
        title={page.title}
      />

      <ScrollView className={componentStyles(primaryColor)}>
        <div className="wrapper">
          <div className="current-project-status">
            <p>
              <strong>Current project:</strong>
              {selectedProject.id && selectedProject.number && selectedProject.description ? (
                <>
                  {selectedProject.number} - {selectedProject.description}
                </>
              ) : (
                <span>No project selected</span>
              )}
            </p>
          </div>

          <label htmlFor="projectChooser" style={{ marginBottom: "0.5rem", display: "block" }}>
            Choose a project:
          </label>
          <DropDown
            name="projectChooser"
            loading={isLoading}
            onChange={(e) => changeDefaultProject(e.target.value)}
            options={projects.map((p) => ({ label: `${p.number} - ${p.description}`, value: p.id }))}
            style={{ marginBottom: "2rem" }}
            value={selectedProject.id}
          />
        </div>
      </ScrollView>
    </Page>
  );
}

const componentStyles = (primaryColor) => css`
  background-color: ${colors.white};
  padding: 2rem 1rem;

  .wrapper {
    max-width: 800px;
    margin: 0 auto;
  }

  .current-project-status {
    border-radius: 3px;
    margin-bottom: 1rem;
    display: flex;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px ${colors.lightGrey} solid;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
      color: ${colors.darkGrey};
    }
  }

  .seperator {
    height: 1px;
    background-color: ${colors.lightGrey};
    margin-bottom: 1rem;
  }

  label {
    font-weight: 700;
  }

  p {
    strong {
      margin-right: 0.5rem;
    }

    span {
      color: ${colors.darkGrey};
      font-size: 0.9rem;
    }
    svg {
      width: 1rem;
      height: 1rem;
      color: ${colors.darkGrey};
      margin: 0 0.15rem -3px 0rem;
    }
  }
`;

export default SemcompltionProjectChooser;
