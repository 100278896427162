import { useQuery } from "react-query";
import { v1 } from "uuid";
import moment from "moment";
import params from "jquery-param";

import req from "../../utilities/request-utility";

export const useDropdownData = (formData = null) => {
  const {
    data: { data: defaultEmployeeValues = {} } = {},
    isFetching: defaultEmployeeValuesIsFetching,
    status: defaultEmployeeValuesStatus,
    refetch: defaultEmployeeValuesRefetch,
  } = useQuery("defaultEmployeeValues", () => req()(`semcotime/projects/default-employee-values/${formData.externalId}`), {
    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    // staleTime: 24 * 60 * 60 * 1000,
  });

  const {
    data: { data: defaultProjectSubProjectAndActivity = {} } = {},
    status: defaultProjectSubProjectAndActivityStatus,
  } = useQuery(
    "defaultProjectSubProjectAndActivity",
    () => req()(`semcotime/projects/default-project-sub-project-and-activity`),
    { keepPreviousData: true, refetchOnWindowFocus: false, retry: false, staleTime: 24 * 60 * 60 * 1000 }
  );

  const { data: { data: project = [] } = {}, status: projectStatus } = useQuery(
    "project",
    () => req()(`semcotime/projects`),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  const {
    data: { data: subProject = [] } = {},
    status: subProjectStatus,
    isFetching: subProjectIsFetching,
  } = useQuery(
    ["subProjects", { projectId: formData.project }],
    ({ queryKey }) => {
      const [key, { projectId }] = queryKey;

      const project = typeof projectId !== "undefined" ? projectId : defaultProjectSubProjectAndActivity.projectId;
      if (!project) return;

      return req()(`semcotime/projects/${project}/sub-projects`);
    },
    {
      enabled: !!defaultProjectSubProjectAndActivity.projectId || typeof formData.project !== "undefined",
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  const { data: { data: projectActivity = [] } = {}, status: projectActivityStatus } = useQuery(
    ["projectActivity", { date: formData.date, projectId: formData.project, subProjectId: formData.subProject }],
    ({ queryKey }) => {
      const [key, { date, projectId, subProjectId }] = queryKey;

      const project = typeof projectId !== "undefined" ? projectId : defaultProjectSubProjectAndActivity.projectId;
      const subProject =
        typeof subProjectId !== "undefined" ? subProjectId : defaultProjectSubProjectAndActivity.subProjectId;
      if (!project || !subProject) return;

      return req()(
        `semcotime/projects/${project}/sub-projects/${subProject}/activities?${params({
          date: date ? date : moment().format("yyyy-MM-DD"),
        })}`
      );
    },
    {
      enabled:
        !!defaultProjectSubProjectAndActivity.projectId ||
        !!defaultProjectSubProjectAndActivity.subProjectId ||
        (typeof formData.project !== "undefined" &&
          typeof formData.subProject !== "undefined" &&
          formData.subProject !== ""),
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  const { data: { data: dabTradeCodes = [] } = {}, isFetching: dabTradeIsFetching } = useQuery(
    "dabTrades",
    () => req()(`semcotime/dab-trade-codes`),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  const { data: { data: dabLocations = [] } = {}, isFetching: dabLocationsIsFetching } = useQuery(
    "dabLocations",
    () => req()(`semcotime/dab-locations`),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  const {
    data: { data: nptHottLabels = [] } = {},
    isFetching: nptHottLabelsIsFetching,
    status: nptHottLabelsStatus,
    refetch: nptHottLabelsRefetch,
  } = useQuery("nptHottLabels", () => req()(`semcotime/npt-hott-labels`), {
    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });

  return {
    dabLocations,
    dabLocationsIsFetching,
    dabTradeCodes,
    dabTradeIsFetching,
    defaultEmployeeValues:
      defaultEmployeeValuesStatus === "loading" || defaultEmployeeValuesStatus === "error" ? {} : defaultEmployeeValues,
    defaultEmployeeValuesIsFetching,
    defaultEmployeeValuesStatus,
    defaultEmployeeValuesRefetch,
    defaultProjectSubProjectAndActivityData:
      defaultProjectSubProjectAndActivityStatus === "loading" || defaultProjectSubProjectAndActivityStatus === "error"
        ? {}
        : defaultProjectSubProjectAndActivity,
    defaultProjectSubProjectAndActivityStatus,
    nptHottLabels:
      nptHottLabelsStatus === "loading" || nptHottLabelsStatus === "error"
        ? []
        : nptHottLabelsStatus === "success"
        ? nptHottLabels.length !== 0
          ? nptHottLabels.map((d) => ({
              ...d,
              id: v1(),
              WorkOrderNo: "",
              OperationNo: "",
              Comment: "",
              Hours: 0,
            }))
          : []
        : [],
    nptHottLabelsIsFetching,
    nptHottLabelsRefetch,
    nptHottLabelsStatus,
    projectData:
      projectStatus === "loading"
        ? []
        : project.map((d) => ({
            ...d,
            detailedReportingEnabled: d.detailedReportingEnabled
              ? d.detailedReportingEnabled.toLowerCase() === "yes"
                ? true
                : false
              : false,
          })),
    projectActivityData:
      projectActivityStatus === "loading" || projectActivityStatus === "idle" || projectActivityStatus === "error"
        ? []
        : projectActivity,
    projectStatus,
    projectActivityStatus,
    subProjectData:
      subProjectStatus === "loading" || subProjectStatus === "idle" || subProjectStatus === "error" ? [] : subProject,
    subProjectStatus,
    subProjectIsFetching,
  };
};
