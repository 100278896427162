import React from "react";
// css
import { css } from "emotion";
import colors from "../../../style/colors";
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import { CheckCircleIcon, CloseCircleIcon } from "mdi-react";
import ActionWrapper from "../../ui/ActionWrapper";

function ForemanTeamUser(props) {
  return (
    <ActionWrapper onClick={props.onClick} className={`${componentStyles(props)} ${props.isSelected ? "selected" : ""} `}>
      {getProfilePictureFromUserObject(props.user)}
      <div className="content">
        <p className="name">{props.user.name}</p>
        <p className="usergroup">{`${props.user.userGroup.title} | Ticket No: ${props.user.ticketNumber} `}</p>
      </div>

      {props.isSelected && <CheckCircleIcon className="check-icon" />}
      {props.isDeletable && <CloseCircleIcon className="close-icon" />}
    </ActionWrapper>
  );
}

const componentStyles = (props) => css`
  border: 1px ${colors.lightGrey} solid;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -1px;
  width: 100%;
  border-radius: 0;

  &.selected {
    border-right: 2px ${colors.green} solid;
    background-color: ;
  }

  .content {
    margin-left: 0.45rem;
    flex-grow: 1;
    text-align: left;
  }

  .usergroup {
    color: ${colors.darkGrey};
    font-size: 0.9rem;
  }

  .check-icon {
    color: ${colors.green};
  }

  .close-icon {
    color: ${colors.red};
  }
`;

export default ForemanTeamUser;
