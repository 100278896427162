import React, { Component } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { ChevronDownIcon } from "mdi-react";
import PropTypes from "prop-types";
//Style
import common from "../../style/common";
import colors from "../../style/colors";

/** This is a customly styled drop-down list
 *
 * It accepts the following props:
 * @param {*} props - A react style object
 * @param {object} style - A react style object
 * @param {string} className  - A class or a list of classes
 * @param {function} onChange - Fires when somthing is is selected
 * @param {string} initText - The placeholder text to appear before something is selected
 * @param {string} placeholder - Can be used instead of initText
 * @param {Object[]} dropDownListContent - an array of elements to show.
 * @param {integer} dropDownListContent[].id - Id of the element to show.
 * @param {string} dropDownListContent[].title - Title of the element to show.
 * @
 * 
 * OR
 *
 * [{ value: XX, name: YY}]
 * ```
 * 
 * EXAMPLE:
 * 
 * ```
 * <DropDownList
            dropDownListContent={this.state.dropDownListContent}
            selectedDropDownItemId={this.state.selectedDropDownItemId}
            onListChange={event => {
              this.setState({
                selectedDropDownContentId: event.target.value
              });
            }}
          />
 * ```
 */

class DropDownList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * Clears the current value of select element when the previous state of selectedContentId
     * is not equal to previous props of selectedContentId, e.g. previous props is equal to ''
     */
    if (prevState.selectedContentId !== prevProps.selectedContentId) {
      this.setState({ selectedContentId: prevProps.selectedContentId });
    }
  }

  dropdownChanged = (e) => {
    this.props.onChange(e);
  };

  render() {
    return (
      <div style={this.props.style} className={`${componentStyle(this.props)} ${this.props.className || ""}`}>
        <select
          value={
            this.props.selectedContentId
              ? this.props.selectedContentId
              : this.state.selectedContentId
              ? this.state.selectedContentId
              : 0
          }
          onChange={(e) => this.dropdownChanged(e)}
          name={this.props.name}
        >
          <option value="" hidden>
            {this.props.initText || this.props.placeholder || "Tryk for at vælge type"}
          </option>
          {this.props.dropDownListContent &&
            this.props.dropDownListContent.map((item, itemIndex) => (
              <option value={item.contentId || item.value || item.id} key={itemIndex}>
                {item.contentName || item.name || item.title}
              </option>
            ))}
        </select>
        <ChevronDownIcon />
      </div>
    );
  }
}

const componentStyle = (props) => css`
  display: flex;
  align-items: end;

  select option:hidden {
    display: none;
  }

  select {
    background: none;
    width: 100%;
    border: 1px solid ${colors.midGrey};
    background: transparent;
    padding-left: 1rem;
    padding-right: 2.5rem;
    display: block;
    height: ${props.height ? props.height : "40px"};
    font-size: 1rem;
    font-family: ${common.fontStack};
    appearance: none;
    color: #6e6e6e;

    &:focus {
      outline: 0;
      border: 1px solid ${props.primaryColor};
    }
  }

  svg {
    margin-left: -40px;
    height: 40px;
    pointer-events: none;
  }
`;

DropDownList.propTypes = {
  /** object for overriding css styles */
  style: PropTypes.object,
  /**  A class or a list of classes used for selecting classes that live inside DropDownList.jsx*/
  className: PropTypes.string,
  /** Function to be executed when an item is selected */
  onChange: PropTypes.func,
  /** The text that appears before anything is selected */
  initText: PropTypes.string,
  /** The elements to be shown in the dropdownlist, look above for required structure on the elements */
  dropDownListContent: PropTypes.array,
};

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

export default connect(mapStateToProps)(DropDownList);
