import React, { useState } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Divider, Input, message, Modal, notification, Rate, Row } from "antd";
import ThumbsUpDown from "@mui/icons-material/ThumbsUpDown";
import req from "../../utilities/request-utility";

import { version } from "../../../package.json";

// Redux actions
import { showDialog } from "../../actions/uiActions";

// Styling
import colors from "../../style/colors";

// Utilities
import clearLocalStorage from "../../utilities/clear-local-storage";
import getAppName from "../../utilities/get-app-name";

// Components
import InlineSpinner from "../ui/InlineSpinner";
import ListItem from "../ui/ListItem";
import Toggle from "../ui/Toggle";
import Button from "../ui/Button";
import { AlertDecagramIcon, ChevronDownIcon } from "mdi-react";
import breakpoints from "../../config/breakpoints";

const UserSettings = (props) => {
  let {
    userData: user,
    settingsLoading: loading,
    settingsError: error,
    settings,
    lang,
    activeIdentifiers,
    updateSetting,
    showDialog,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFeedbackSubmitting, setIsFeedbackSubmitting] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const ratings = { 1: "Very Dissatisfied", 2: "Dissatisfied", 3: "Neutral", 4: "Satisfied", 5: "Very Satisfied" };

  const submitFeedback = async () => {
    try {
      setIsFeedbackSubmitting(true);
      if (rating === 0) {
        setIsFeedbackSubmitting(false);
        return notification.info({
          duration: 0,
          message: "Please choose a star/s",
        });
      }

      const data = {
        slug: "semcotime-dk-feedback",
        Subject: "Semcotime DK App - A user rated the app",
        recipients: ["johndoe@semcomaritime.com "], //placeholder only
        values: { Rating: rating, Value: ratings[rating], Feedback: feedback, User: user.name },
      };

      await req().post(`AHAMAtic/sendEmail/`, data);
      await req().post(`semcotime/ifs-feedback/`, data);

      setRating(0);
      setFeedback("");
      setIsFeedbackSubmitting(false);
      setIsModalVisible(false);
      return notification.success({
        duration: 2,
        message: "Thank you for your feedback!",
      });
    } catch (error) {
      setIsFeedbackSubmitting(false);
      console.log("error: ", error);
      notification.error({
        duration: 2,
        message: "Something went wrong when submitting feedback",
      });
    }
  };

  return (
    <>
      <div style={{ maxWidth: `${breakpoints.lg}px`, margin: "0 auto" }}>
        {/* Spinner */}
        {loading && !error && <InlineSpinner style={{ marginTop: "1rem" }} />}

        {/* General settings */}
        {!loading && !error && settings.length > 0 && (
          <>
            <h2 style={{ padding: "1rem", marginTop: "1rem" }}>General</h2>
            {settings.map((setting, settingIndex) =>
              !setting.options ? (
                <ListItem
                  title={setting.title}
                  maxWidth={breakpoints.lg}
                  subTitle={
                    setting.content ||
                    // If there is no content and there is a selectedOption-value, show the selected value
                    (setting.selectedOption && setting.options.filter((f) => f.id === setting.selectedOption))[0].name
                  }
                  wrapContent={true}
                  key={"setting-" + settingIndex}
                  contentRight={
                    <Toggle
                      on={setting.value}
                      loading={activeIdentifiers.indexOf(setting.identifier) !== -1}
                      onClick={() => updateSetting({ identifier: setting.identifier, value: !setting.value })}
                    />
                  }
                />
              ) : (
                <div className={dropdownWrapperStyle()} key={setting.identifier}>
                  <p>{lang.language}</p>
                  <select
                    onChange={(e) => updateSetting({ identifier: setting.identifier, value: e.target.value })}
                    value={setting.selectedOption}
                  >
                    {setting.options.map((option) => (
                      <option value={option.id} key={option.identifier}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <ChevronDownIcon />
                </div>
              )
            )}
          </>
        )}

        {/* Sign out setting */}
        {!loading && (
          <div className={contentContainer()}>
            {/* Hardcoded setting */}
            <h2 style={{ margin: "2rem 0 1rem" }}>{lang.extra}</h2>
            <p style={{ marginBottom: "1rem" }}>{lang.logOutWarning}</p>
            <Button
              styleType={"warning"}
              onClick={() =>
                showDialog({
                  icon: <AlertDecagramIcon />,
                  styleType: "warning",
                  title: lang.logOutWarningTitle,
                  content: lang.logOutWarningContent,
                  primaryActionTitle: lang.logOutWarningAction,
                  primaryAction: () =>
                    clearLocalStorage((err) => {
                      window.location = window.location.origin + "/" + getAppName() + "/";
                    }),
                })
              }
            >
              Log out
            </Button>
          </div>
        )}
        <Divider />

        <p className="meta" style={{ padding: "1rem", textAlign: "center" }}>
          Ekko App version {version}
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Button
              styleType={"primary"}
              onClick={() => {
                caches.keys().then((cacheNames) => {
                  cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                  });
                });
                alert("The cache has been cleared. The page will now reload.");

                window.location.reload(true);
              }}
            >
              Get Latest Update
            </Button>
          </div>
        </div>

        <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ textAlign: "center" }}>
              <ThumbsUpDown style={{ fontSize: "35px", color: "#103447" }} />
            </div>

            <div style={{ textAlign: "center" }}>
              <a onClick={() => setIsModalVisible(!isModalVisible)}>
                <b>We'd love to hear your feedback!</b>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Modal
        closable={!isFeedbackSubmitting}
        visible={isModalVisible}
        footer={[
          <Button key="submit" type="primary" active={isFeedbackSubmitting} onClick={() => submitFeedback()}>
            Submit
          </Button>,
        ]}
        onCancel={isFeedbackSubmitting ? null : () => setIsModalVisible(!isModalVisible)}
      >
        <Row justify="center">
          <Col span={24}>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              How satisfied are you with this app?
            </p>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Rate style={{ fontSize: "50px" }} value={rating} onChange={(e) => setRating(e)} />
          </Col>
          <Col span={24} style={{ textAlign: "center", marginBottom: "15px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "0px",
              }}
            >
              {ratings[rating]}
            </p>
          </Col>
          {rating != 0 && rating <= 3 && (
            <>
              <Col span={24}>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  How can we make it better?
                </p>
              </Col>
              <Col span={24}>
                <Input.TextArea
                  placeholder="(Optional)"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  rows={4}
                />
              </Col>
            </>
          )}
        </Row>

        <br />
      </Modal>
    </>
  );
};

const dropdownWrapperStyle = () => css`
  position: relative;
  background: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;

  @media screen and (min-width: ${breakpoints.lg}px) {
    border: 1px ${colors.midGrey} solid;
  }

  p {
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: -2.9rem;
  }

  select {
    padding: 2rem 1rem 1.25rem 1rem;
    appearance: none;
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
    border: 0;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    outline: 0;
  }

  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const contentContainer = () => css`
  margin: 1rem 0;
  padding: 0 1rem;

  p {
    white-space: initial;
  }
`;

const mapStateToProps = (state) => ({
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
