import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { isEmpty, isEqual, sortBy } from "lodash";
import param from "jquery-param";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, FloppyIcon } from "mdi-react";
import moment from "moment";

// NEW
import { Form, Modal, Checkbox, Radio, Divider, Popover, Popconfirm, notification } from "antd";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDropdownData } from "../../WorkTimeDK/hooks";
import workHourState from "../../../config/semcotimedk-workhour-states";

// css
import { css } from "emotion";
import common from "../../../style/common";
import colors from "../../../style/colors";
import DatePicker from "../../ui/DatePicker";
import InlineSpinner from "../../ui/InlineSpinner";
import ScrollView from "../../ui/ScrollView";
import req from "../../../utilities/request-utility";

//ui
import Button from "../../ui/Button";
import DropDown from "../../ui/DropDown";
import { toggleWorkHourModal } from "../../../actions/uiActions";
import TimeRegistrationList from "../../semco-time/TimeRegistrationList";
import NPTAndHOTTLabeling from "../../semco-time/nptAndHott";

const ForemanWorkHourForm = (props, ref) => {
  const dispatch = useDispatch();
  const {
    data: registration = {},
    externalId = null,
    onDelete: onDeleteParent,
    onSubmit: onSubmitParent,
    sentToIFSAt,
    lang,
    page = "foreman",
    userId = null,
  } = props;
  const { data = {}, ifsData = null } = registration;

  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const modal = useSelector(({ ui }) => ui.workHourModal);
  const loggedUserAuthorizedForeman = useSelector(({ auth }) => auth.user.loggedUserAuthorizedForeman, isEqual);
  console.log("loggedUserAuthorizedForeman: ", loggedUserAuthorizedForeman);

  const archived = useMemo(() => (registration.lockedByIFS ? true : false), [registration.lockedByIFS]);
  const needsProcessing = useMemo(() => (registration.sentToIFSAt ? true : false), [registration.sentToIFSAt]);
  const lockedByForeman = useMemo(() => (registration.lockedByForeman ? true : false), [registration.lockedByForeman]);
  // STATES
  const [foremen, setForemen] = useState([]);
  const [isFormReady, setIsFormReady] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorLoadingFormData, setErrorLoadingFormData] = useState(false); //error page
  const [tradeCodesList, setTradeCodesList] = useState([]);
  const [showMobileFormAction, setShowMobileFormAction] = useState(false);

  const getForemen = () => {
    try {
      setIsFormReady(false);

      req()(`semcotime/foremen?${param({ foremanGroupIdentifier: page === "admin" ? "ADMIN" : "FOREMEN" })}`).then(
        ({ data }) => {
          const assignedIds = data.assigned.map((d) => d.id);

          setForemen([
            ...data.assigned,
            { disabled: true, id: "", name: "------------------" },
            ...data.all.filter((d) => !assignedIds.includes(d.id)),
          ]);
        }
      );

      setIsFormReady(true);
    } catch (err) {
      setErrorLoadingFormData(true);
    }
  };

  const schema = yup.object().shape({
    //validations
    date: yup.string().required("Date is required"),
    detailedReportingEnabled: yup.boolean(),
    // foreman: page === "admin" ? yup.string().required("Foreman is required") : yup.string(),
    foreman: yup.string().required("Foreman is required"),
    location: yup.string().required("Location is required"),
    project: yup.string().required("Project is required"),
    subProject: yup.string().required("Sub Project is required"),
    activity: yup.string().required("Project Activity is required"),
    trade: yup.string().required("Trade is required"),
    timeRegistrations: yup
      .array()
      .min(1, "Times Registrations must have at least 1 record")
      .of(
        yup.object().shape({
          approvedByForeman: yup.boolean(),
        })
      )
      // .compact((v) => !v.approvedByForeman) //check if there is time registration that is not SAVED
      .test({
        name: "allIsApproved",
        message: "All time registrations must be approved",
        test: (val) => val.filter((d) => !d.approvedByForeman).length === 0, //check if all saved time registrations are approved
      }),
    nptHott: yup
      .array()
      .of(
        yup.object().shape({
          Category: yup.string(),
          Hours: yup.number(),
          WorkOrderNo: yup.string(),
          OperationNo: yup.string(),
          Description: yup.string(),
        })
      )
      .test({
        name: "allLabelHours",
        message: "Please complete the required fields",
        test: (val) =>
          val.filter(
            (d) =>
              d.Hours > 0 &&
              (d.WorkOrderNo === "" ||
                (d.Category === "NPT" ? false : d.OperationNo === "") ||
                (d.Description.replace(/[^a-zA-Z ]/g, "") === "Custom" && d.Comment === ""))
          ).length === 0, //check if there is a label with hours that missing required fields
      }),
  });

  const defaultValues = {
    date: "",
    detailedReportingEnabled: false,
    foreman: "",
    location: "",
    project: "",
    subProject: "",
    activity: "",
    trade: "",
    endOfRotation: false,
    type: "time",
    timeRegistrations: [],
    break: "",
    nptHott: [],
  };

  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    dabLocations,
    dabLocationsIsFetching,
    dabTradeCodes,
    dabTradeIsFetching,
    defaultEmployeeValues,
    defaultEmployeeValuesIsFetching,
    defaultEmployeeValuesStatus,
    defaultEmployeeValuesRefetch,
    defaultProjectSubProjectAndActivityData,
    defaultProjectSubProjectAndActivityStatus,
    nptHottLabels,
    nptHottLabelsIsFetching,
    nptHottLabelsRefetch,
    nptHottLabelsStatus,
    projectData,
    projectActivityData,
    projectStatus,
    projectActivityStatus,
    subProjectData,
    subProjectStatus,
    subProjectIsFetching,
  } = useDropdownData({ ...watch(), externalId });

  const getProjectActivityPlaceholder = () => {
    if (projectActivityStatus === "loading" || projectActivityStatus === "idle") {
      return lang.loading;
    } else if (projectActivityData.length === 0) {
      return lang.noActivities;
    } else {
      return lang.chooseActivity;
    }
  };

  const getSubProjectPlaceholder = () => {
    if (subProjectStatus === "loading" || subProjectStatus === "idle") {
      return lang.loading;
    } else if (subProjectData.length === 0) {
      return "No subprojects found";
    } else {
      return lang.chooseASubproject;
    }
  };

  useEffect(() => {
    setTradeCodesList(dabTradeCodes);
  }, [dabTradeCodes.length]);

  useEffect(() => {
    if (!nptHottLabelsIsFetching && nptHottLabelsStatus === "success") {
      if (
        (!isEmpty(registration) && data.nptHott && data.nptHott.length === 0) ||
        (isEmpty(registration) && getValues("date") === "")
      ) {
        // if viewed record is draft/ not approved OR new form, load default label values
        setValue("nptHott", nptHottLabels);
      }
    }
  }, [nptHottLabelsIsFetching, watch("project")]);

  useEffect(() => {
    // form onload edit or from local storage
    if (isEmpty(registration) && !defaultEmployeeValuesIsFetching) {
      const { employeeTrade = "" } = defaultEmployeeValues;

      // to condition when editing
      setValue("trade", employeeTrade);
    }
  }, [defaultEmployeeValuesIsFetching]);

  useEffect(() => {
    if (
      !defaultEmployeeValuesIsFetching &&
      defaultEmployeeValues.employeeTrade &&
      getValues("trade") !== defaultEmployeeValues.employeeTrade
    ) {
      const defaultEmployeeTrade = dabTradeCodes.filter((d) => d.DabTradeId === defaultEmployeeValues.employeeTrade)[0];
      notification.warning({
        description: `${lang.tradeNotDefault} (${defaultEmployeeTrade.DabTradeId} - ${defaultEmployeeTrade.DabTradeDesc}).`,
      });
    }
  }, [getValues("trade")]);

  useEffect(() => {
    getForemen();

    // deletes old form layout in local storage
    let localStorageForm = localStorage.getItem(`semcotime-last-time-input-${userId}`);
    if (localStorageForm) {
      if (
        !JSON.parse(localStorageForm).hasOwnProperty("detailedReportingEnabled") &&
        !JSON.parse(localStorageForm).hasOwnProperty("nptHott")
      ) {
        console.log("-- OLD FORM DETECTED AND DELETED --");
        localStorage.removeItem(`semcotime-last-time-input-${userId}`);
      }
    }
  }, []);

  useEffect(() => {
    nptHottLabelsRefetch();
    defaultEmployeeValuesRefetch();
    if (!isEmpty(registration)) {
      //if edit
      reset(registration.data);
    } else {
      // if add with last form used
      const lastForm = localStorage.getItem(`semcotime-last-time-input-${userId}`);
      if (lastForm) {
        return reset(JSON.parse(lastForm));
      } else {
        reset(defaultValues);
      }
    }
  }, [JSON.stringify(registration)]);

  const onSubmit = async (formData, toLocalStorage = false, action = "draft") => {
    setSubmittingForm(true);
    if (!formData.detailedReportingEnabled) {
      //remove label data if the selected project is detailedReportingEnabled
      await onSubmitParent({ ...{ ...formData, nptHott: [] }, action, type: "time" });
    } else {
      await onSubmitParent({ ...formData, action, type: "time" });
    }
    if (action === "draftAndNextDay") {
      setValue("date", moment(formData.date).add(1, "days").format("YYYY-MM-DD"));
    }

    setShowMobileFormAction(false);
    setSubmittingForm(false);
  };

  const onDelete = async () => {
    setIsDeleting(true);
    await onDeleteParent();
    setIsDeleting(false);
  };

  // console.log("FORM VAL: ", watch("nptHott")[0]);
  const disabled = archived || needsProcessing;
  const renderForm = () => {
    return (
      <>
        <Form layout="vertical" onFinish={handleSubmit} className={componentStyles()}>
          <ScrollView
            className={scrollViewStyles({ topBarHeight: common.topBarHeight, bottomPanelHeight: archived ? 0 : 66 })}
          >
            {/* <div className="meta">
                <InlineSpinner title={lang.loadingForm} />
              </div> */}

            {registration.state === workHourState.deleted ? (
              <div className="deleted">DELETED IN IFS</div>
            ) : (
              registration.state === workHourState.lockedByForeman &&
              needsProcessing === true && <div className="processing">PROCESSING IN IFS</div>
            )}

            {/* DATE */}
            <p>{lang.date} *</p>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DatePicker
                      disabled={disabled}
                      value={value}
                      onDateChange={(date) => {
                        onChange(date);
                      }}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* LOCATION */}
            <p>{lang.location}</p>
            <Controller
              name="location"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      disabled={disabled}
                      loading={dabLocationsIsFetching}
                      onChange={onChange}
                      options={
                        dabLocations
                          ? sortBy(
                              dabLocations.map((d) => ({ label: d.DabLocationValue, value: d.DabLocationValue })),
                              "DabLocationId"
                            )
                          : []
                      }
                      placeholder={lang.chooseADabLocation}
                      value={value}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* PROJECT */}
            <p>{lang.project}</p>
            <Controller
              name="project"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      disabled={disabled}
                      loading={projectStatus === "loading"}
                      name="project"
                      onChange={(e) => {
                        setValue("subProject", "");
                        setValue("activity", "");
                        setValue(
                          "detailedReportingEnabled",
                          projectData.filter((d) => d.id === e.target.value)[0].detailedReportingEnabled
                        ); //NPT AND HOTT ENABLED

                        onChange(e);
                      }}
                      options={projectData.map((d) => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
                      placeholder={lang.timeRegistrationFormProjectPlaceholder}
                      value={value}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* SUB-PROJECT */}
            {getValues("project") && (
              <>
                <p>{lang.subproject}</p>
                <Controller
                  name="subProject"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                    return (
                      <>
                        <DropDown
                          disabled={disabled}
                          loading={subProjectIsFetching}
                          onChange={onChange}
                          options={subProjectData.map((d) => ({ label: `${d.id} - ${d.description}`, value: d.id }))}
                          placeholder={getSubProjectPlaceholder()}
                          value={value}
                        />
                        {error && <h6 className="fieldError">{error.message}</h6>}
                      </>
                    );
                  }}
                />
              </>
            )}

            {/* ACTIVITY */}
            {getValues("project") && getValues("subProject") && (
              <>
                <p>{lang.activity}</p>
                <Controller
                  name="activity"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                    return (
                      <>
                        <DropDown
                          disabled={disabled}
                          loading={projectActivityStatus === "loading"}
                          name="activity"
                          onChange={onChange}
                          options={projectActivityData.map((d) => ({ label: `${d.no} - ${d.description}`, value: d.id }))}
                          placeholder={getProjectActivityPlaceholder()}
                          value={value}
                        />
                        {error && <h6 className="fieldError">{error.message}</h6>}
                      </>
                    );
                  }}
                />
              </>
            )}

            {/* TRADE */}
            <p>{lang.dabTrade}</p>
            <Controller
              name="trade"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <DropDown
                      disabled={disabled}
                      loading={dabTradeIsFetching || defaultEmployeeValuesStatus === "loading"}
                      name="trade"
                      onChange={onChange}
                      options={
                        tradeCodesList
                          ? tradeCodesList.map((d) => ({
                              label: `${d.DabTradeId} - ${d.DabTradeDesc}`,
                              value: d.DabTradeId,
                            }))
                          : []
                      }
                      placeholder={lang.chooseADabTrade}
                      value={value}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* {page === "admin" && ( */}
            <>
              {/* FOREMAN */}
              <p>{lang.timeRegistrationFormForeman}</p>
              <Controller
                name="foreman"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                  return (
                    <>
                      <DropDown
                        // loading={}
                        disabled={disabled}
                        name="foreman"
                        onChange={onChange}
                        options={foremen.map((d) => ({ disabled: d.disabled, label: d.name, value: d.id }))}
                        placeholder={lang.timeRegistrationFormForemanPlaceholder}
                        value={parseInt(value)}
                      />
                      {error && <h6 className="fieldError">{error.message}</h6>}
                    </>
                  );
                }}
              />
            </>
            {/* )} */}

            <Divider />
            {/* TIME REGISTRATION */}
            <Controller
              name="timeRegistrations"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <TimeRegistrationList
                      name="timeRegistrations"
                      value={value}
                      disabled={disabled}
                      onChange={(e) => {
                        // set End Of Rotation to TRUE when selecting Heli Time Home
                        // if true , no localstorage
                        setValue(
                          "endOfRotation",
                          e.some((d) => d.savedByBlueCollar && d.type === 6)
                        );

                        onChange(e);
                      }}
                      date={watch("date")}
                      location={watch("location")}
                      locationIFSRules={dabLocations}
                      locationIsLoading={dabLocationsIsFetching}
                      isForeman={true}
                    />
                    {error && <h6 className="fieldError">{error.message}</h6>}
                  </>
                );
              }}
            />

            {/* END OF ROTATION */}
            <Controller
              name="endOfRotation"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                return (
                  <>
                    <Checkbox name="endOfRotation" disabled={disabled} onChange={onChange} checked={value}>
                      {console.log("lang: ", lang)}
                      {lang.foremanLastDayInRotation}
                    </Checkbox>
                  </>
                );
              }}
            />
            {/* BREAK */}
            {watch("location") === "OnShore" && (
              <Controller
                name="break"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                  return (
                    <>
                      <Radio.Group name="break" disabled={disabled} onChange={onChange} size="large" value={value}>
                        <Radio value="paid">Paid Break</Radio>
                        <Radio value="unpaid">Unpaid Break</Radio>
                      </Radio.Group>
                    </>
                  );
                }}
              />
            )}
            {/* NPT and HOTT Labeling */}
            {getValues("detailedReportingEnabled") && (
              <>
                {nptHottLabelsIsFetching && <InlineSpinner title={"Fetching NPT / HOTT Labels"} />}
                {!nptHottLabelsIsFetching && (
                  <Controller
                    name="nptHott"
                    control={control}
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
                      return (
                        <NPTAndHOTTLabeling
                          disabled={disabled}
                          timeRegistrations={watch("timeRegistrations")}
                          nptHottLabels={value}
                          onChange={onChange}
                          error={error}
                        />
                      );
                    }}
                  />
                )}
              </>
            )}
          </ScrollView>
          {!isMobile && isFormReady && !archived && !needsProcessing && (
            <div className={footerButtonStyles()}>
              {/* <div className="col-25"> */}
              <Button
                active={submittingForm}
                onClick={handleSubmit((e) => onSubmit(e, false, !isEmpty(registration) ? "update" : "draft"))}
              >
                {submittingForm && (
                  <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                )}
                {lang.foremanRegistrationWorkTimeSave}
              </Button>
              {isEmpty(registration) && (
                <Button active={submittingForm} onClick={handleSubmit((e) => onSubmit(e, false, "draftAndNextDay"))}>
                  {submittingForm && (
                    <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                  )}
                  {lang.foremanRegistrationWorkTimeSaveAndCopyToNextDay}
                </Button>
              )}
              {loggedUserAuthorizedForeman ? (
                <>
                  {/* show submit buttons for authorized foremen */}
                  <Button active={submittingForm} onClick={handleSubmit((e) => onSubmit(e, false, "submit"))}>
                    {submittingForm && (
                      <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                    )}
                    {lang.foremanRegistrationWorkTimeSaveAndSubmit}
                  </Button>
                  {!isEmpty(registration) && (
                    <Button active={submittingForm} onClick={handleSubmit((e) => onSubmit(e, false, "submitAndNext"))}>
                      {submittingForm && (
                        <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                      )}
                      {lang.foremanRegistrationWorkTimeSaveAndSubmitGoToNextDay}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {!isEmpty(registration) && (
                    <>
                      <Button
                        active={submittingForm}
                        onClick={handleSubmit((e) => onSubmit(e, false, "updateAndMoveToNextDay"))}
                      >
                        {submittingForm && (
                          <InlineSpinner
                            size="14"
                            style={{
                              display: "inline-block",
                              margin: "0 0.5rem -0.6rem 0rem",
                            }}
                          />
                        )}
                        {lang.foremanRegistrationWorkTimeSaveAndGoToNextDay}
                      </Button>
                    </>
                  )}
                </>
              )}

              {!isEmpty(registration) && (
                <Popconfirm
                  disabled={submittingForm}
                  title={lang.confirmText}
                  onConfirm={onDelete}
                  okText={lang.confirmYes}
                  cancelText={lang.confirmNo}
                >
                  <Button disabled={submittingForm} styleType="error">
                    {submittingForm || isDeleting ? (
                      <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </Button>
                </Popconfirm>
              )}
            </div>
          )}
        </Form>
      </>
    );
  };

  const isMobile = useMediaQuery({ maxWidth: "800px" });

  const close = () => {
    dispatch(toggleWorkHourModal(false));
  };

  const mobileFormActions = () => {
    return (
      <div className={footerButtonStylesMobile()}>
        <div className="mobile-buttons">
          <Button
            active={submittingForm}
            onClick={handleSubmit((e) => onSubmit(e, false, !isEmpty(registration) ? "update" : "draft"))}
          >
            {lang.foremanRegistrationWorkTimeSave}
          </Button>
        </div>
        {loggedUserAuthorizedForeman ? (
          <>
            <div className="mobile-buttons">
              <Button active={submittingForm} onClick={handleSubmit((e) => onSubmit(e, false, "submit"))}>
                {lang.foremanRegistrationWorkTimeSaveAndSubmit}
              </Button>
            </div>
            {!isEmpty(registration) && (
              <Button active={submittingForm} onClick={handleSubmit((e) => onSubmit(e, false, "submitAndNext"))}>
                {submittingForm && (
                  <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                )}
                {lang.foremanRegistrationWorkTimeSaveAndSubmitGoToNextDay}
              </Button>
            )}
          </>
        ) : (
          <>
            {!isEmpty(registration) && (
              <div className="mobile-buttons">
                <Button active={submittingForm} onClick={handleSubmit((e) => onSubmit(e, false, "updateAndMoveToNextDay"))}>
                  {lang.foremanRegistrationWorkTimeSaveAndGoToNextDay}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {!isMobile && renderForm()}

      {isMobile && (
        <Modal
          centered
          footer={
            isFormReady &&
            !archived &&
            !needsProcessing && (
              <>
                <Popover
                  placement="topRight"
                  content={mobileFormActions()}
                  visible={showMobileFormAction}
                  title={<b>Pick an action:</b>}
                  onVisibleChange={() => setShowMobileFormAction(!showMobileFormAction)}
                  trigger="click"
                >
                  <Button
                    type="primary"
                    active={submittingForm}
                    onClick={() => setShowMobileFormAction(!showMobileFormAction)}
                  >
                    <FloppyIcon />
                  </Button>
                </Popover>
                {!isEmpty(registration) && (
                  <Popconfirm
                    disabled={submittingForm}
                    title={lang.confirmText}
                    onConfirm={onDelete}
                    okText={lang.confirmYes}
                    cancelText={lang.confirmNo}
                  >
                    <Button disabled={submittingForm} styleType="error">
                      {submittingForm || isDeleting ? (
                        <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </Button>
                  </Popconfirm>
                )}
              </>
            )
          }
          onCancel={close}
          visible={modal}
        >
          {renderForm()}
        </Modal>
      )}
    </>
  );
};

const scrollViewStyles = ({ bottomPanelHeight, topBarHeight }) => css`
  height: calc(96vh - ${topBarHeight}px - ${bottomPanelHeight}px);
  padding: 1rem;

  @media (max-width: 414px) {
    padding: unset;
  }
`;

const componentStyles = (primaryColor) => css`
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px ${colors.midGrey} solid;
  border-left: 3px ${primaryColor} solid;
  padding: 0.65rem;
  margin-bottom: 10px;

  p {
    margin-bottom: 0px;
  }

  .fieldError {
    color: red;
  }

  .deleted {
    width: 100%;
    text-align: center;
    background-color: red;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
  }

  .processing {
    width: 100%;
    text-align: center;
    background-color: blue;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-of-type  {
      margin-bottom: 0;
    }

    & > label {
      width: 30%;
      text-align: right;
      padding-right: 0.65rem;
      font-size: 0.9rem;
      color: ${colors.darkGrey};
    }

    .input {
      width: 100%;
    }

    .time-input {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .extras {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid ${colors.lightGrey};
      padding: 0.75rem 0.75rem 0.75rem 0;
      margin: 0 -0.75rem 0 0rem;

      &:last-of-type {
        border-bottom: 1px solid ${colors.lightGrey};
      }

      .name {
        flex: 1 1 70%;
        padding: 0;
      }

      svg {
        height: 40px; /* WARNING: Magic number */
      }

      input {
        flex: 1 1 2rem;
        margin: 0 0.5rem 0 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    border-left: 1px ${colors.midGrey} solid;
  }

  @media (max-width: 414px) {
    border: none;
    padding: unset;
    margin-bottom: unset;

    .input-wrapper {
      align-items: flex-start;
      flex-direction: column;

      & > label {
        display: block;
        width: unset;
      }
    }
  }
`;

const footerButtonStyles = () => css`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100%);
  background-color: #f8f8f8;
  padding: 10px 10px 10px 0px;
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  display: flex;
  border-top: 1px #103447 solid;

  button {
    margin-left: 5px;
    font-size: 12px;
    width: 10vw;
    height: 8vh;
  }

  .error {
    width: 5vw;
    height: 8vh;
  }

  .checkboxContainer {
    div {
      border: none;

      .checkbox {
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;

    .col-25,
    .col-75 {
      flex-basis: 100%;

      &:nth-child(odd) {
        margin-bottom: 1.25rem;
      }
    }
  }

  @media (max-width: 768px) {
    position: unset;
  }
  @media (max-width: 414px) {
    position: unset;
    padding: unset;
    background-color: unset;
    border: unset;
  }
`;

const footerButtonStylesMobile = () => css`
  display: flex;
  flex-direction: column;

  .mobile-buttons {
    padding: 3px;
  }
`;

export default forwardRef(ForemanWorkHourForm);
