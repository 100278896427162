import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import appConfigReducer from "./appConfigReducer";
import languageReducer from "./languageReducer";
import pagesReducer from "./pagesReducer";
import paper from "./paperReducer";
import sideMenuReducer from "./sideMenuReducer";
import weldLogReducer from "./weldLogReducer";
import redmarkLogReducer from "./redmarkLogReducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";

// Semco
import semcompletionReducer from "./semcompletionReducer";

export default combineReducers({
  appConfig: appConfigReducer,
  auth: authReducer,
  language: languageReducer,
  pages: pagesReducer,
  paper,
  sideMenu: sideMenuReducer,
  ui: uiReducer,
  weldLog: weldLogReducer,
  redmarkLog: redmarkLogReducer,
  semcompletion: semcompletionReducer,
  users: userReducer,
});
