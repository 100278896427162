import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";
import { parse, format } from "date-fns";
import params from "jquery-param";

import colors from "../../style/colors";

// actions
import { updateModalPage } from "../../actions/uiActions";

// ui-components
import ScrollView from "../ui/ScrollView";
import Page from "../ui/Page";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import common from "../../style/common";
import req from "../../utilities/request-utility";

function WorkTimeDetails(props) {
  const { registration, fetchDate } = props;

  const lang = useSelector((s) => s.language.language);
  const selectedUser = useSelector((s) => s.users.selectedUser);
  const selectedUserName = useSelector((s) =>
    s.users.lists.length !== 0 ? s.users.lists.find((d) => d.externalId === s.users.selectedUser).name : null
  );

  // The first element in a the list of registrations for this day (i know.. It makes little sense)
  const registrationExtract = registration.registrations[0];
  const [detailsData, setDetailsData] = useState(null);
  const [wageCodes, setWageCodes] = useState(null);

  useEffect(() => {
    getDetailsData();
    // eslint-disable-next-line
  }, []);

  /** As some data isn't available in the overview, this function gets what is missing from the api */
  async function getDetailsData() {
    try {
      let { data } = await req()(
        `semcotime/work-hours/v3/${registrationExtract.transactionId}?${params({
          date: fetchDate,
          externalId: selectedUser,
        })}`
      );
      setDetailsData(data);
      getWageCodes(data);
    } catch (err) {
      setDetailsData("error");
    }
  }

  /** As the names of the wage codes isn't returned by the api, this function fetches the relevant wageCodes */
  async function getWageCodes(data) {
    let { data: wageCodes } = await req()(
      `semcotime/wage-codes?${params({
        externalId: selectedUser,
        wageType: "increment",
        ids: data.extras.map((el) => el.wageCode).join("+"),
      })}`
    ).catch((err) => setDetailsData("error"));
    setWageCodes(wageCodes);
  }

  function getRotationStatusText(detailsData) {
    if (detailsData.changeShift === true) return lang.changeShift;
    if (detailsData.endOfRotation === true) return `${detailsData.midRotation ? "Last Day" : lang.endOfRotation}`;
    if (detailsData.firstDay === true) return `${detailsData.midRotation ? "First Day (Day)" : lang.firstDayPlanRotation}`;
    if (detailsData.firstDayNightShift === true)
      return `${detailsData.midRotation ? "First Day (Night)" : lang.firstDayPlanRotationNightShift}`;
    if (detailsData.preRotation === true) return lang.beforePlannedRotation;
    if (detailsData.preRotationDayShift === true) return lang.preRotationDay;
    if (detailsData.preRotationNightShift === true) return lang.preRotationNight;
    if (detailsData.oneDayCourse === true) return lang.oneDayCourse;
    if (detailsData.startMultiDayCourse === true) return lang.startMultiDayCourse;
    if (detailsData.course === true) return lang.course;
    if (detailsData.endOfMultiDayCourse === true) return lang.endOfMultiDayCourse;

    // No changes
    return detailsData.midRotation ? "Last Day " : "No rotation status";
  }

  function getParsedDateTime(dateString) {
    try {
      return format(parse(dateString, "yyyyMMddHHmmss", 0), "yyyy-MM-dd HH:mm:ss");
    } catch {
      return lang.noDate;
    }
  }

  /*
  [√] Project,     -> Overview
  [ ] Underprojekt -> Details
  [√] Aktivitet    -> Details + overview...
  [√] Description  -> Details
  [√] Dato:        -> Details
  [√] startTid     -> Details
  [√] sluttid      -> Details
  [√] Extras/Tillæg 
      [√] Navn     -> Overview
      [√] Hours    -> Overview
  */

  return (
    <Page className={componentStyles()}>
      <ScrollView style={{ padding: "0.75rem" }}>
        {detailsData === null && <InlineSpinner style={{ margin: "1rem auto" }} />}
        {detailsData !== null && detailsData === "error" && <StatusBox title="Unable to get data" />}

        {detailsData !== null && detailsData !== "error" && (
          <>
            {/* general */}
            {selectedUserName ? (
              <div className="title-container">
                <h2>{lang.general}</h2>
                <span>{selectedUserName}</span>
              </div>
            ) : (
              <h2>{lang.general}</h2>
            )}
            <div className="text-wrapper">
              <p>{lang.project}:</p>
              <p>{registrationExtract.project}</p>
            </div>
            <div className="text-wrapper">
              <p>{lang.activity}:</p>
              <p>
                {detailsData.activitySequence} - {registrationExtract.activity}
              </p>
            </div>
            <div className="text-wrapper">
              <p>{lang.description}:</p>
              <p>{detailsData.description}</p>
            </div>
            <div className="text-wrapper">
              <p>{lang.rotationStatus}:</p>
              <p>{getRotationStatusText(detailsData, registrationExtract)}</p>
            </div>
            <div className="text-wrapper">
              <p>{lang.midRotation}:</p>
              <p>{detailsData.midRotation ? "Yes" : "No"}</p>
            </div>
            <div className="text-wrapper">
              <p>{lang.absenceNightTime}:</p>
              <p>{detailsData.absenceNightTime ? "Yes" : "No"}</p>
            </div>

            <div className="text-wrapper">
              <p>{lang.courses}:</p>
              <p>{detailsData.courses ? "Yes" : "No"}</p>
            </div>

            <div className="seperator"></div>

            {/* Date and time */}
            <h2>{lang.dateAndTime}</h2>
            <div className="text-wrapper">
              <p>{lang.date}:</p>
              <p>{registrationExtract.date}</p>
            </div>
            <div className="text-wrapper">
              <p>{lang.startTime}:</p>
              <p>{getParsedDateTime(detailsData.startTime)}</p>
            </div>
            <div className="text-wrapper">
              <p>{lang.endTime}:</p>
              <p>{getParsedDateTime(detailsData.endTime)}</p>
            </div>

            {/* Extras */}
            {wageCodes && detailsData.extras.length > 0 && (
              <>
                <div className="seperator"></div>
                <h2>{lang.sc_extra}</h2>
                {detailsData.extras.map((extra) => (
                  <div className="text-wrapper">
                    {wageCodes.filter((w) => w.id === extra.wageCode).length > 0 ? (
                      <p>{wageCodes.filter((w) => w.id === extra.wageCode)[0].name}:</p>
                    ) : (
                      <p>Unknown wage code</p>
                    )}
                    <p>{extra.hours}</p>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </ScrollView>
    </Page>
  );
}

const componentStyles = () => css`
  background-color: white;
  padding-top: ${common.topBarHeight}px;

  p {
    padding: 0.5rem 0 0 0;
  }

  p.description {
    padding: 0.25rem 0 0 0;
    color: ${colors.darkGrey};
  }

  div.seperator {
    background-color: ${colors.lightGrey};
    height: 1px;
    margin: 1.5rem -0.75rem 1rem -0.75rem;
  }

  div.text-wrapper {
    display: flex;

    p:first-child {
      padding-right: 0.5rem;
    }

    p:not(:first-child) {
      color: ${colors.darkGrey};
      overflow: hidden;
    }
  }

  .title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(WorkTimeDetails);
