import getAppName from "./get-app-name";

export default function (apiConfig) {
  let manifestEl = document.getElementById("elManifest");
  let baseURL = manifestEl.getAttribute("data-base-url");

  // Special value. You can set base-url to CURRENT_DOMAIN to use the current domain instead of a external domain
  if (baseURL === "CURRENT_DOMAIN") baseURL = `${window.location.origin}/api/`;

  manifestEl.setAttribute("href", `${baseURL}apps/manifest/${getAppName()}`);

  if (process.env.NODE_ENV === "development") {
    manifestEl.setAttribute("href", `http://192.168.1.16:3030/api/apps/manifest/${getAppName()}`);
  }
}
