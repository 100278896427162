import { useQuery } from "react-query";

import req from "../../../../../utilities/request-utility";

function useOverviewData() {
  const {
    isLoading: masterGroupsLoading,
    error: masterGroupsError,
    data: { data: masterGroupsData = [] } = {},
  } = useQuery("MasterGroups", () => req()("/masterGroups"), {
    staleTime: 60 * 60 * 1000, //1 hour
  });
  const masterGroups = {
    isLoading: masterGroupsLoading,
    error: masterGroupsError,
    data: masterGroupsData.map((d) => ({ ...d, name: d.title })),
  };

  const {
    isLoading: customersLoading,
    error: customersError,
    data: { data: customersData = [] } = {},
  } = useQuery("Customers", () => req()("/semcompletion/customers"), {
    staleTime: 60 * 60 * 1000, //1 hour
  });
  const customers = {
    isLoading: customersLoading,
    error: customersError,
    data: customersData,
  };

  return { customers, masterGroups };
}

export default useOverviewData;
