import React, { useCallback, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';

// styling
import { css } from 'emotion';
import colors from '../../../style/colors';
//actions

import PunchStatus from './PunchStatus';
import ButtonRounded from '../../ui/ButtonRounded';
import InlineSpinner from '../../ui/InlineSpinner';
import TextInput from '../../ui/TextInput';
import DatePicker from '../../ui/DatePicker';
import ImageUploadMultiple from '../ImageUploadMultiple';
import { AccountAlertIcon, AccountCheckIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from 'mdi-react';
import punchStatuses from '../../../config/punchStatuses';
import req from '../../../utilities/request-utility';
import { format } from 'date-fns';
import { showDialog } from '../../../actions/uiActions';

function PunchSingleHeader({ punch, punchAllowances, getPunch }) {
	const [isFormVisible, setIsFormVisibile] = useState(false);

	return (
		<div className={container() + ` ${punch.status} ${isFormVisible ? 'form-visible' : 'form-hidden'}`}>
			{/* Form for open punches */}
			{punch.status === punchStatuses.open && (
				<OpenPunchForm
					getPunch={getPunch}
					punchAllowances={punchAllowances}
					isFormVisible={isFormVisible}
					setIsFormVisibile={setIsFormVisibile}
					punch={punch}
				/>
			)}

			{/* Form for Cleared punches */}
			{punch.status === punchStatuses.cleared && (
				<ClearPunchForm
					getPunch={getPunch}
					punchAllowances={punchAllowances}
					isFormVisible={isFormVisible}
					setIsFormVisibile={setIsFormVisibile}
					punch={punch}
				/>
			)}

			{/* Closed punches is just closed */}
			{punch.status === punchStatuses.closed && (
				<ClosePunchForm
					getPunch={getPunch}
					isFormVisible={isFormVisible}
					punch={punch}
					punchAllowances={punchAllowances}
					setIsFormVisibile={setIsFormVisibile}
				/>
			)}
		</div>
	);
}

function OpenPunchForm({ isFormVisible, setIsFormVisibile, punch, getPunch, punchAllowances }) {
	const [formData, setFormData] = useState({
		name: '',
		date: format(new Date(), 'yyyy-MM-dd', 0),
		comment: '',
		image: undefined
	});
	const [formLoading, setFormloading] = useState(false);

	function onChange(e, formData) {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	function clearPunch(formData) {
		let apiReadyData = {
			...formData,
			date: formData.date.replace(/-/g, '')
		};

		if (!formData.name || !formData.date || !formData.comment)
			return notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Some fields are missing. Make sure to fill out name, date and comment and try again'
			});

		setFormloading(true);

		req()
			.put(`semcompletion/v2/punches/${punch.id}/clear`, apiReadyData)
			.then(({ data }) => {
				setIsFormVisibile(false);
				setTimeout(getPunch, 300);
				setFormloading(false);
			})
			.catch(err => {
				console.log(err);
				setFormloading(false);
			});
	}

	return (
		<>
			<div className="header-row">
				<PunchStatus status={punch.status} />
				<div className="content">
					{punchAllowances.canClear && (
						<ButtonRounded
							onClick={() => setIsFormVisibile(!isFormVisible)}
							className="btn-show-form"
							secondary={true}>
							<ChevronDownIcon /> Show clear punch form
						</ButtonRounded>
					)}
				</div>
			</div>

			<AnimateHeight animateOpacity={true} height={isFormVisible ? 'auto' : 0}>
				{/* If closed show clear form */}
				<div className="row">
					<div className="col-50">
						<label htmlFor="name">
							Name <span className="required">*</span>
						</label>
						<TextInput name="name" value={formData.name} onChange={e => onChange(e, formData)} />
					</div>

					<div className="col-50">
						<label htmlFor="date">
							Date <span className="required">*</span>
						</label>
						<DatePicker name="date" value={formData.date} onChange={e => onChange(e, formData)} />
					</div>
				</div>

				<div className="row">
					<div className="col-100">
						<label htmlFor="">
							Comment <span className="required">*</span>
						</label>
						<TextInput
							name="comment"
							maxLength={300}
							value={formData.comment}
							onChange={e => onChange(e, formData)}
							showCount={true}
							style={{ height: 120 }}
							type="textarea"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-100">
						<label htmlFor="">Image</label>
						<ImageUploadMultiple
							className="image-upload"
							placeholder="Upload image"
							urlPrefix="/semcompletion"
							uploadedFiles={formData.images}
							onFileUpdate={files => setFormData({ ...formData, image: files[0] })}
						/>
					</div>
				</div>

				<div className="actions row">
					<div className="left"></div>
					<div className="center">
						<ButtonRounded
							onClick={() => setIsFormVisibile(!isFormVisible)}
							className="btn-hide-form"
							secondary={true}>
							<ChevronUpIcon /> Hide clear punch form
						</ButtonRounded>
					</div>
					<div className="right">
						<ButtonRounded
							color={colors.vividBlue}
							disabled={formLoading}
							onClick={() => clearPunch(formData)}>
							{formLoading ? (
								<InlineSpinner
									size="14"
									style={{ display: 'inline-block', margin: '0 0.5rem -0.6rem 0rem' }}
								/>
							) : (
								<AccountCheckIcon />
							)}{' '}
							Clear punch
						</ButtonRounded>
					</div>
				</div>
			</AnimateHeight>
		</>
	);
}

const ClosePunchForm = ({ getPunch, isFormVisible, punch, punchAllowances, setIsFormVisibile }) => {
	const [formLoading, setFormloading] = useState(false);
	const [formData, setFormData] = useState({
		name: '',
		comment: '',
		image: undefined
	});

	const onChange = useCallback(
		(e, formData) => {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		},
		[setFormData]
	);

	const openPunch = useCallback(() => {
		if (!formData.name || !formData.comment)
			return notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Some fields are missing. Make sure to fill out name and comment and try again'
			});

		setFormloading(true);

		req()
			.put(`semcompletion/v2/punches/${punch.id}/open`, formData)
			.then(({ data }) => {
				setIsFormVisibile(false);
				setTimeout(getPunch, 300);
				setFormloading(false);
			})
			.catch(err => {
				console.log(err);
				setFormloading(false);
			});
	}, [JSON.stringify(formData)]);

	return (
		<>
			<div className="actions row" style={{ margin: '0 auto' }}>
				<div className="left">
					<PunchStatus status={punch.status} />
				</div>
				<div className="right">
					{punchAllowances.canOpen && (
						<ButtonRounded
							color={colors.red}
							disabled={formLoading}
							onClick={() => setIsFormVisibile(!isFormVisible)}>
							<AccountAlertIcon style={{ marginRight: '0.5rem', width: '1.25rem', height: '1.25rem' }} />{' '}
							Open punch
						</ButtonRounded>
					)}
				</div>
			</div>
			<AnimateHeight animateOpacity={true} height={isFormVisible ? 'auto' : 0}>
				<div className="row">
					<div className="col-100">
						<label htmlFor="name">
							Name <span className="required">*</span>
						</label>
						<TextInput value={formData.name} onChange={e => onChange(e, formData)} name="name" />
					</div>
				</div>
				<div className="row">
					<div className="col-100">
						<label htmlFor="comment">
							Comment <span className="required">*</span>
						</label>
						<TextInput
							name="comment"
							maxLength={300}
							value={formData.comment}
							onChange={e => onChange(e, formData)}
							showCount={true}
							style={{ height: 120 }}
							type="textarea"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-100">
						<label htmlFor="image">Image</label>
						<ImageUploadMultiple
							value={formData.image}
							onFileUpdate={files => setFormData({ ...formData, image: files[0] })}
							name="image"
						/>
					</div>
				</div>
				<div className="actions row">
					<div className="left"></div>
					<div className="center">
						<ButtonRounded
							onClick={() => setIsFormVisibile(!isFormVisible)}
							className="btn-hide-form"
							secondary={true}>
							<ChevronUpIcon /> Hide open punch form
						</ButtonRounded>
					</div>
					<div className="right">
						<ButtonRounded color={colors.red} disabled={formLoading} onClick={() => openPunch(formData)}>
							{formLoading ? (
								<InlineSpinner
									size="14"
									style={{ display: 'inline-block', margin: '0 0.5rem -0.6rem 0rem' }}
								/>
							) : (
								<AccountAlertIcon />
							)}{' '}
							Open punch
						</ButtonRounded>
					</div>
				</div>
			</AnimateHeight>
		</>
	);
};

function ClearPunchForm({ isFormVisible, punchAllowances, setIsFormVisibile, punch, getPunch }) {
	const [formData, setFormData] = useState({
		name: '',
		comment: '',
		image: undefined
	});
	const [formLoading, setFormloading] = useState(false);

	const dispatch = useDispatch();

	function onChange(e, formData) {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	function openPunch(formData) {
		if (!formData.name || !formData.comment)
			return notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Some fields are missing. Make sure to fill out name and comment and try again'
			});

		setFormloading(true);

		req()
			.put(`semcompletion/v2/punches/${punch.id}/open`, formData)
			.then(({ data }) => {
				setIsFormVisibile(false);
				setTimeout(getPunch, 300);
				setFormloading(false);
			})
			.catch(err => {
				console.log(err);
				setFormloading(false);
			});
	}
	function closePunch() {
		dispatch(
			showDialog({
				styleType: 'neutral',
				title: 'Close punch',
				content: "You're about to close this punch. Do you want to continue?",
				primaryActionTitle: 'Yes, close punch',
				primaryAction: () => {
					setFormloading(true);

					req()
						.put(`semcompletion/v2/punches/${punch.id}/close`)
						.then(({ data }) => {
							setIsFormVisibile(false);
							setTimeout(getPunch, 300);
							setFormloading(false);
						})
						.catch(err => {
							console.log(err);
							setFormloading(false);
						});
				}
			})
		);
	}

	return (
		<>
			<div className="actions row" style={{ margin: '0 auto' }}>
				<div className="left">
					<PunchStatus status={punch.status} />
				</div>
				<div className="right">
					{punchAllowances.canOpen && (
						<ButtonRounded
							color={colors.red}
							disabled={formLoading}
							onClick={() => setIsFormVisibile(!isFormVisible)}>
							<AccountAlertIcon style={{ marginRight: '0.5rem', width: '1.25rem', height: '1.25rem' }} />{' '}
							Open punch
						</ButtonRounded>
					)}
					{punchAllowances.canClose && (
						<ButtonRounded
							color={colors.green}
							disabled={formLoading}
							style={{ marginLeft: '0.75rem' }}
							onClick={closePunch}>
							{formLoading ? (
								<InlineSpinner
									size="14"
									style={{ display: 'inline-block', margin: '0 0.5rem -0.6rem 0rem' }}
								/>
							) : (
								<CheckIcon style={{ marginRight: '0.5rem', width: '1.25rem', height: '1.25rem' }} />
							)}{' '}
							Close punch
						</ButtonRounded>
					)}
				</div>
			</div>

			<AnimateHeight animateOpacity={true} height={isFormVisible ? 'auto' : 0}>
				<div className="row">
					<div className="col-100">
						<label htmlFor="name">
							Name <span className="required">*</span>
						</label>
						<TextInput value={formData.name} onChange={e => onChange(e, formData)} name="name" />
					</div>
				</div>

				<div className="row">
					<div className="col-100">
						<label htmlFor="comment">
							Comment <span className="required">*</span>
						</label>
						<TextInput
							name="comment"
							maxLength={300}
							value={formData.comment}
							onChange={e => onChange(e, formData)}
							showCount={true}
							style={{ height: 120 }}
							type="textarea"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-100">
						<label htmlFor="image">Image</label>
						<ImageUploadMultiple
							value={formData.image}
							onFileUpdate={files => setFormData({ ...formData, image: files[0] })}
							name="image"
						/>
					</div>
				</div>

				<div className="actions row">
					<div className="left"></div>
					<div className="center">
						<ButtonRounded
							onClick={() => setIsFormVisibile(!isFormVisible)}
							className="btn-hide-form"
							secondary={true}>
							<ChevronUpIcon /> Hide open punch form
						</ButtonRounded>
					</div>
					<div className="right">
						<ButtonRounded color={colors.red} disabled={formLoading} onClick={() => openPunch(formData)}>
							{formLoading ? (
								<InlineSpinner
									size="14"
									style={{ display: 'inline-block', margin: '0 0.5rem -0.6rem 0rem' }}
								/>
							) : (
								<AccountAlertIcon />
							)}{' '}
							Open punch
						</ButtonRounded>
					</div>
				</div>
			</AnimateHeight>
		</>
	);
}

const container = () => css`
	background-color: ${colors.white};
	padding: 0.85rem;
	border-bottom: 1px ${colors.midGrey} solid;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

	.button-icon {
		margin-right: 0.35rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.image-upload label {
		margin-bottom: 0.75rem;
	}

	&.Open {
		border-left: 3px ${colors.red} solid;
	}
	&.Cleared {
		border-left: 3px ${colors.vividBlue} solid;
	}
	&.Closed {
		border-left: 3px ${colors.green} solid;
	}

	&.form-visible {
		.btn-show-form {
			opacity: 0;
			pointer-events: none;
			cursor: initial;
		}
	}
	&.form-hidden {
		.btn-hide-form {
			opacity: 0;
		}
	}

	.btn-hide-form,
	.btn-show-form {
		opacity: 1;
		transition: opacity 260ms ease;
		margin: 0 auto;
	}

	.row {
		padding: 0;
		margin: 1rem auto;
	}

	.header-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1200px;
		margin: 0 auto;

		.content {
			flex-grow: 1;
		}
	}

	.actions {
		display: flex;
		margin-bottom: 0px;
		align-items: center;

		.left,
		.center,
		.right {
			flex: 1 0 33%;
		}

		.right {
			display: flex;
			justify-content: flex-end;
		}
	}

	.required {
		color: #808080;
	}
`;

export default PunchSingleHeader;
