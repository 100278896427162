import React, { useState } from "react";
import { notification } from "antd";
import { css } from "emotion";

// styling
import colors from "../../../../style/colors";

// utlities
import req from "../../../../utilities/request-utility";
import getErrorMessage from "../../../../utilities/get-error-message";

// ui-Components
import DropDown from "../../../ui/DropDown";
import Button from "../../../ui/Button";
import { useDispatch } from "react-redux";
import ScrollView from "../../../ui/ScrollView";
import { hideModalPage } from "../../../../actions/uiActions";

import InlineSpinner from "../../../ui/InlineSpinner";

import useOverviewData from "./hooks/useOverviewData";

function PunchModal({ currentValues = [], masterGroup, refreshData }) {
  const dispatch = useDispatch();
  const { customers } = useOverviewData();

  const [formData, setFormData] = useState({ customer: "" });
  const [formLoading, setFormloading] = useState(false);

  const isFormValid = (formData) => {
    if (!formData.customer) {
      return {
        status: false,
        message: "Make sure to fill out customer and then try again",
      };
    }

    return { status: true };
  };

  const save = async () => {
    const isFormValidData = isFormValid(formData);
    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    try {
      setFormloading(true);
      await req().post(`/masterGroups/${masterGroup}/customers`, formData);
      dispatch(hideModalPage());
      if (refreshData) refreshData();
      setFormloading(false);

      notification.success({
        duration: 7,
        message: "SUCCESS",
        description: "You have successfully added a customer to the user group",
      });
    } catch (error) {
      notification.error({ duration: 7, message: "FAILED", description: getErrorMessage(error) });
      setFormloading(false);
    }
  };

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const customerIds = currentValues.map((d) => d.id);

  return (
    <ScrollView className={container()}>
      <div className="row">
        <div className="col-100">
          <label htmlFor="">
            Customer
            <span className="required">*</span>
          </label>
          <DropDown
            name="customer"
            onChange={onChange}
            options={
              customers.data
                ? customers.data
                    .filter((d) => (customerIds.includes(d.id) ? false : true))
                    .map((d) => ({ label: d.name, value: d.id }))
                : []
            }
            placeholder="Click to choose"
            value={formData["customer"]}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-100">
          <Button disabled={formLoading} onClick={save}>
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            Add Customer to User Group
          </Button>
        </div>
      </div>
    </ScrollView>
  );
}

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  .required {
    color: #808080;
  }
`;

export default PunchModal;
