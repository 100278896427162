import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InlineSpinner from "./InlineSpinner";
import colors from "../../style/colors";

/** Generic button component to be used across our application.
 * @param {*} props
 * @param {string} props.styleType - The type of button. Supported types: `neutral|error|secondary`
 * @param {boolean} props.disabled - Makes the button disabled
 * @param {boolean} props.active - Shows a spinner inside the button
 * @param {function} props.onClick - Main event handler
 * @param {string} props['data-test-id'] - Test id
 * @param {reactElement} props.iconLeft - DEPRECATION WARNING! May be removed! See examples. Icon to display left of the title
 * @param {reactElement} props.iconRight - DEPRECATION WARNING! May be removed! See examples. Icon to display right of the title
 * @param {string} props.title - DEPRECATION WARNING! May be removed! See examples. Title of the button
 *
 * Previously we used props to control title and icons but have since opted in for using
 * the children prop, making it way more flexible.
 *
 * @example
 * ```jsx static
 * // Current usage
 * <Button>
 *   <TickIcon />
 *   Terminate
 * </Button>
 *
 *
 * // LEGACY USAGE! DONT DO THIS GOING FORWARD!
 * <Button
 *   iconLeft={<TickIcon style={{ marginRight: "0.5rem" }} />}
 *   title={"Terminate"}
 * />
 * ```
 */
const Button = (props) => (
  <button
    className={`${style(props)} ${props.className || ""} ${props.secondary === true ? "secondary" : "primary"} ${
      props.small ? "small" : ""
    }`}
    style={props.style}
    disabled={props.disabled ? props.disabled : false}
    onClick={!props.active ? props.onClick : null}
    data-test-id={props["data-test-id"]}
  >
    {/* Main content OR spinner */}
    {props.active ? (
      <InlineSpinner color={props.primaryColor} size={24} style={{ marginTop: "-4px", marginBottom: "-10px" }} />
    ) : (
      <>
        {props.title || ""}
        {props.children && typeof props.children === "function" && props.children()}
        {props.children && typeof props.children === "object" && props.children}
        {props.children && typeof props.children === "string" && props.children}
      </>
    )}
  </button>
);

const style = (props) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0 1.5rem;
  transition: background-color 120ms ease;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: initial;
  }

  &.small {
    font-size: 0.9rem;
    height: 2rem;
    border-radius: 1rem;
  }

  &.primary {
    background-color: ${props.color ? props.color : props.primaryColor};
    color: ${colors.white};

    &:hover {
      background-color: ${tinycolor(props.color ? props.color : props.primaryColor)
        .lighten(5)
        .toString()};
    }
    &:active {
      background-color: ${tinycolor(props.color ? props.color : props.primaryColor)
        .darken(5)
        .toString()};
    }
    &:focus {
      outline: 0px;
      background-color: ${tinycolor(props.color ? props.color : props.primaryColor)
        .lighten(5)
        .toString()};
    }
  }

  &.secondary {
    background-color: ${tinycolor(props.primaryColor).setAlpha(0.1).toString()};
    color: ${props.primaryColor};

    &:hover {
      background-color: ${tinycolor(props.primaryColor).setAlpha(0.05).toString()};
    }
    &:active {
      background-color: ${tinycolor(props.primaryColor).setAlpha(0.15).toString()};
    }
    &:focus {
      background-color: ${tinycolor(props.primaryColor).setAlpha(0.05).toString()};
      outline: 0px;
    }
  }

  &:disabled {
    background-color: ${colors.lightGrey};
    color: ${colors.midDarkGrey};

    &:hover {
      cursor: not-allowed;
      background-color: ${colors.lightGrey};
    }
    &:active {
      background-color: ${colors.lightGrey};
    }
    &:focus {
      outline: 0px;
    }
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

export default connect(mapStateToProps)(Button);

Button.propTypes = {
  /** The id to use for testing. Is written as data-test-id="prop" to the DOM */
  "data-test-id": PropTypes.string,
  /** Describes what type of button to use, primary,secondary etc. */
  buttonType: PropTypes.string,
  /** Used for displaying neutral/error/Ok events on the button */
  styleType: PropTypes.string,
  /** Used for handling submitting/creation, for example set this =true when waiting for response on network request */
  active: PropTypes.bool,
  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,
  /** The function which is called when the button is clicked */
  onClick: PropTypes.func,
  /** The svg icon used on the button, this is optional */
  iconLeft: PropTypes.object,
};
