import React, { Component } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import TinyAnimate from "TinyAnimate";
import { notification } from "antd";

// Components
import { UploadIcon, DeleteIcon, AlertCircleIcon } from "mdi-react";
import InlineSpinner from "../ui/InlineSpinner";

// Utilities
import req from "../../utilities/request-utility";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";
class ImageUploadMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      imageSliderRef: React.createRef(),
    };
  }

  componentDidMount() {
    this.props.uploadedFiles && this.getUploadedFiles();
  }

  // create list of objects
  getUploadedFiles = () => {
    let uploadedFiles = this.props.uploadedFiles.map((x) =>
      Object.assign(
        {},
        {
          status: "uploaded",
          image: x.image,
          originalImage: x.originalImage,
          deleteToken: x.deleteToken ? x.deleteToken : undefined,
        }
      )
    );

    // Assign uploaded files to files-object
    if (uploadedFiles.length > 0) {
      const files = [...this.state.files];
      const updated = files.concat(uploadedFiles);
      this.setState({ files: updated }, () => {});
    }
  };

  onFileSelect = (e) => {
    // TODO: Validate file size and type
    let file = e.target.files[0];

    // Check if file is selected
    if (!file) return; // No file is selected

    // Check file size
    if (file.size > 200000000) {
      return notification.error({
        duration: 7,
        message: "FAILED",
        description: "The file you are trying to upload is too big. Choose a smaller file",
      });
    } else if (file.size > 39999900) {
      // TO DO! Reimplement with dialog, once that is implemented
      return notification.error({
        duration: 7,
        message: "FAILED",
        description:
          "You're about to upload a rather large file. This might take some time. Consider finding some decent Wi-Fi.",
      });
    }

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);

    // Start upload and set state
    this.uploadFile(formData, this.state.files.length);
    this.setState({
      files: [...this.state.files, { status: "uploading", progress: 0 }],
    });

    // Scroll upload div into view
    setTimeout(this.scrollImagePreviewToRight, 100);

    // Reset input
    e.target.value = "";
  };

  uploadFile = (file, index) => {
    req()
      .post(`${this.props.urlPrefix || ""}/images`, file, { onUploadProgress: (e) => this.onUploadProgress(e, index) })
      // .post("/file", file, { onUploadProgress: e => this.onUploadProgress(e, index) })
      .then(({ data }) => data)
      .then((data) => this.onFileUploadSuccess(data, index))
      .catch((err) => this.onFileUploadFailure(err, index));
  };

  deleteFile = (index) => {
    let files = [...this.state.files];
    files[index].status = "deleted";
    this.setState({
      files: files,
    });
    this.props.onFileUpdate(this.state.files.filter((f) => f.status === "uploaded"));
  };

  onUploadProgress = (progress, index) => {
    let progressPercent = (progress.loaded / progress.total) * 100;
    let files = [...this.state.files];
    files[index].progress = progressPercent;
    this.setState({
      files: files,
    });
  };

  onFileUploadSuccess = (res, index) => {
    let files = [...this.state.files];
    files[index] = {
      status: "uploaded",
      image: res.image,
      originalImage: res.originalImage,
      deleteToken: "deleteable",
    };
    this.setState({
      files: files,
    });
    this.props.onFileUpdate(this.state.files.filter((f) => f.status === "uploaded"));
  };

  onFileUploadFailure = (err, index) => {
    notification.error({
      duration: 7,
      message: "FAILED",
      description: "Could not upload your image. Try again or contact support@toecho.dk",
    });

    if (this.state.files[index]) {
      let files = [...this.state.files];
      files[index].status = "error";
      this.setState({
        files: files,
      });
    }
  };

  scrollImagePreviewToRight = () => {
    if (this.props.disablePreview) return null;

    let { imageSliderRef } = this.state;
    TinyAnimate.animate(
      imageSliderRef.current.scrollLeft,
      imageSliderRef.current.scrollWidth - imageSliderRef.current.getBoundingClientRect().width,
      300,
      (x) => {
        imageSliderRef.current.scrollLeft = x;
      },
      "easeInOutQuart"
    );
  };

  render() {
    let { placeholder, primaryColor, style, boxPadding, allowedAmountOfImages = 1, disablePreview } = this.props;
    let { files, imageSliderRef } = this.state;

    return (
      <div className={componentStyle(primaryColor, boxPadding) + " " + this.props.className} style={style}>
        {allowedAmountOfImages > files.filter((f) => f.status === "uploading" || f.status === "uploaded").length && (
          <label style={{ fontWeight: 400 }}>
            <UploadIcon />
            {placeholder}
            <input type="file" accept="image/*" onChange={this.onFileSelect} />
          </label>
        )}

        {/* Image preview in post-like carousel */}
        {!disablePreview && files.filter((f) => f.status === "uploading" || f.status === "uploaded").length > 0 && (
          <div className="image-preview-container">
            <div className="scroll-hider" ref={imageSliderRef}>
              {files.map((image, index) => {
                if (image.status === "uploading") {
                  return (
                    <div className="image-preview" key={index}>
                      <InlineSpinner
                        title={image.progress < 100 ? Math.round(image.progress) + "%" : "Arbejder med filen..."}
                      />
                      <div className="progress-bar-container">
                        <div style={{ flexGrow: image.progress || 0 }} className="progress-bar colored" />
                        <div style={{ flexGrow: 100 - (image.progress || 0) }} className="progress-bar transparent" />
                      </div>
                    </div>
                  );
                }
                if (image.status === "uploaded") {
                  return (
                    <div className="image-preview" key={index}>
                      <img src={`${image.image}`} alt="" onLoad={this.scrollImagePreviewToRight} />
                      <DeleteIcon onClick={() => this.deleteFile(index)} />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const previewHeight = 150;
const componentHeight = 40;

const componentStyle = (primaryColor, boxPadding) => css`
  font-size: 1rem;
  color: ${colors.black};

  /* Upload button/Input */
  label {
    display: flex !important;
    align-items: center;
    height: ${componentHeight}px;
    background: ${colors.white};
    border: 1px ${colors.midGrey} solid;
    color: ${colors.black};
    font-family: ${common.fontStack};
    margin-bottom: ${boxPadding};
    border-radius: 3px;

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.45rem;
      color: ${colors.darkGrey};
      border-right: 1px solid ${colors.midGrey};
    }
  }

  input[type="file"] {
    display: none;
  }

  .image-preview-container {
    height: ${previewHeight}px;
    overflow: hidden;
    margin: 0 -${boxPadding};

    .scroll-hider {
      padding: 0 ${boxPadding};
      height: ${previewHeight + 50}px;
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .image-preview {
      width: 150px;
      white-space: initial;
      position: relative;
      vertical-align: top;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 150px;
      min-width: 150px;
      max-width: 80vw;
      border: 1px ${colors.midGrey} solid;
      border-radius: 3px;
      margin-right: 0.5rem;

      img {
        width: 150%;
      }

      &:last-child {
        margin-right: 0;
      }

      /* Delete icon */
      svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        padding: 0.25rem;
        border-bottom-left-radius: 4px;
        color: ${colors.darkGrey};
        border: 1px solid ${colors.midGrey};
        background-color: ${colors.white};
      }
    }
  }
  /* Progress bar */
  .progress-bar-container {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 3px;
    display: flex;
    justify-content: space-between;

    .progress-bar {
      height: 3px;
    }

    .progress-bar.colored {
      background-color: ${primaryColor};
    }
  }
`;
const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

export default connect(mapStateToProps, null)(ImageUploadMultiple);
