import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { Modal, notification } from "antd";

// styling
import { css } from "emotion";
import colors from "../../../../style/colors";

// hooks
import useOverviewData from "./hooks/useOverviewData";

// ui-Components
import InlineSpinner from "../../../ui/InlineSpinner";
import DropDownList from "../../../ui/DropDownList";
import Page from "../../../ui/Page";
import { AlertCircleOutlineIcon, ArrowBackIcon, PlusIcon, TrashCanIcon } from "mdi-react";
import Button from "../../../ui/Button";
import usePageAndBackButtonURL from "../../../../hooks/usePageAndBackButtonURL";
import TopBar from "../../../ui/TopBar";
import pageNavigator from "../../../../utilities/page-navigator";
import StatusBox from "../../../ui/StatusBox";
import ListItem from "../../../ui/ListItem";
import { showModalPage } from "../../../../actions/uiActions";

import ModalCustom from "./Modal";

import req from "../../../../utilities/request-utility";

function PunchOverview(props) {
  const dispatch = useDispatch();

  const [masterGroup, setMasterGroup] = useState("");
  const { backButtonURL } = usePageAndBackButtonURL(props);
  const { masterGroups } = useOverviewData();

  const {
    isFetching: masterGroupsFetching = false,
    error: masterGroupsError = null,
    data: { data: masterGroupsData = [] } = {},
    refetch: masterGroupsRefetch,
  } = useQuery(
    ["CustomersProjects", masterGroup],
    ({ queryKey: [key, masterGroup] }) => {
      if (masterGroup === "") return new Promise((resolve) => resolve({ data: [] }));
      return req()(`/masterGroups/${masterGroup}/customers`);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000, //1 hour
    }
  );

  const onDelete = async (id) => {
    console.log(id);

    Modal.confirm({
      title: "Are you sure you want to remove this customer to the user group?",
      onOk: async () => {
        try {
          await req().delete(`/masterGroups/${masterGroup}/customers/${id}`);
          masterGroupsRefetch();
          notification.success({
            duration: 7,
            message: "SUCCESS",
            description: "You have successfully removed a customer to this user group",
          });
        } catch (error) {
          notification.error({ duration: 7, message: "FAILED", description: error.message });
        }
      },
    });
  };

  return (
    <Page className={container()}>
      <TopBar
        title="Master Group Customers"
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
      />
      {!masterGroups.isLoading && !masterGroups.error && (
        <>
          <div className="header">
            <div className="row">
              <DropDownList
                placeholder="User Groups"
                style={{ flexBasis: "20%" }}
                name="masterGroup"
                value={masterGroup}
                dropDownListContent={masterGroups.data}
                onChange={(e) => setMasterGroup(e.target.value)}
                selectedContentId={masterGroup}
              />
              <div className="punch-count-container" />
              {masterGroup && (
                <Button
                  onClick={() =>
                    dispatch(
                      showModalPage({
                        content: (
                          <ModalCustom
                            currentValues={masterGroupsData}
                            masterGroup={masterGroup}
                            refreshData={masterGroupsRefetch}
                          />
                        ),
                      })
                    )
                  }
                  style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
                >
                  Add Customer <PlusIcon style={{ margin: "-0.6rem 0 -0.35rem 0" }} />
                </Button>
              )}
            </div>
          </div>
          <div style={{ padding: "1rem 0" }}>
            {masterGroupsFetching && !masterGroupsError && <InlineSpinner />}
            {!masterGroupsFetching && masterGroupsError && <StatusBox />}
            {!masterGroup && <StatusBox title="Please select a user group" icon={<AlertCircleOutlineIcon />} content="  " />}
            {masterGroup && !masterGroupsFetching && !masterGroupsError && masterGroupsData.length === 0 && (
              <StatusBox
                title="No assign customers found for this user group"
                icon={<AlertCircleOutlineIcon />}
                content="  "
              />
            )}

            {masterGroup &&
              !masterGroupsFetching &&
              !masterGroupsError &&
              masterGroupsData.length !== 0 &&
              masterGroupsData.map((d) => {
                return (
                  <ListItem
                    onClick={() => onDelete(d.connectionId)}
                    title={d.number}
                    subTitle={d.name}
                    iconRight={<TrashCanIcon />}
                    key={d.id}
                  />
                );
              })}
          </div>
        </>
      )}
    </Page>
  );
}

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

export default PunchOverview;
