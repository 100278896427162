import React from 'react';
import { Document, Page } from 'react-pdf';
import isIOS from 'is-ios';

function PDFRenderComponent(props) {
	const { orientation = 'landscape', pdfData, size = 'A4' } = props;

	return (
		<Document
			orientation={orientation}
			size={size}
			file={pdfData}
			onLoadError={e => console.log('PDF Render Error: ', e)}
			renderMode={isIOS ? 'svg' : 'canvas'}>
			<Page style={{ marginLeft: '50%' }} pageNumber={1} />
		</Document>
	);
}

export default PDFRenderComponent;
