import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";

// hooks
import useOverviewData from "./hooks/useOverviewData";

// ui-Components
import InlineSpinner from "../../ui/InlineSpinner";
import DropDownList from "../../ui/DropDownList";
import TextInput from "../../ui/TextInput";
import Page from "../../ui/Page";
import { AlertCircleOutlineIcon, ArrowBackIcon, ChevronRightIcon, PlusIcon } from "mdi-react";
import Button from "../../ui/Button";
import TabView from "../../ui/TabView";
import TabBar from "../../ui/TabBar";
import usePageAndBackButtonURL from "../../../hooks/usePageAndBackButtonURL";
import TopBar from "../../ui/TopBar";
import pageNavigator from "../../../utilities/page-navigator";
import StatusBox from "../../ui/StatusBox";
import ListItem from "../../ui/ListItem";
import { showModalPage } from "../../../actions/uiActions";
import PunchModal from "./PunchModal";
import punchStatuses from "../../../config/punchStatuses";
import PunchStatus from "./PunchStatus";

let timeout;
function PunchOverview(props) {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    punchList: "",
    punchOwner: "",
    punchOrigin: "",
    punchLocation: "",
    punchCategory: "",
    system: "",
    searchterm: "",
  });

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabs = [
    { title: <PunchStatus offsetBottom={false} status={punchStatuses.open} />, color: colors.red },
    { title: <PunchStatus offsetBottom={false} status={punchStatuses.cleared} />, color: colors.vividBlue },
    { title: <PunchStatus offsetBottom={false} status={punchStatuses.closed} />, color: colors.green },
  ];

  const { backButtonURL } = usePageAndBackButtonURL(props);
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);
  const { openPunches, clearedPunches, closedPunches, statusCounts, getStatusCounts, metaData, getData } = useOverviewData();

  const isRIG = selectedProject.projectType === "02B" ? true : false;

  useEffect(() => {
    getStatusCounts({ filterData });
    getData({ tabIndex: 0, filterData });
    getData({ tabIndex: 1, filterData });
    getData({ tabIndex: 2, filterData });
    // eslint-disable-next-line
  }, []);

  const { punchList } = filterData;
  useEffect(() => {
    getStatusCounts({ filterData, setAsLoading: true });
    // eslint-disable-next-line
  }, [punchList]);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      refreshData({ clearDataBeforeFetch: true });
    }, 300);
    // eslint-disable-next-line
  }, [filterData]);

  function refreshData({ clearDataBeforeFetch = false } = {}) {
    getData({ tabIndex: 0, refreshData: true, filterData, clearDataBeforeFetch });
    getData({ tabIndex: 1, refreshData: true, filterData, clearDataBeforeFetch });
    getData({ tabIndex: 2, refreshData: true, filterData, clearDataBeforeFetch });
  }

  function handleDropdownChange(e, filterData) {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <Page className={container()}>
      <TopBar
        title={`Punch (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />}
      />
      {!metaData.loading && !metaData.error && (
        <>
          <div className="header">
            <div className="row">
              {!isRIG && metaData.data.punchLists.length > 1 && (
                <DropDownList
                  placeholder="Punch list"
                  style={{ flexBasis: "20%" }}
                  name="punchList"
                  value={filterData.punchList}
                  dropDownListContent={[{ id: "", name: "All lists" }, ...metaData.data.punchLists]}
                  onChange={(e) => handleDropdownChange(e, filterData)}
                  selectedContentId={filterData.punchList}
                />
              )}
              <div className="punch-count-container">
                {statusCounts.loading && (
                  <div style={{ color: colors.darkGrey, fontSize: "0.85rem" }}>
                    <InlineSpinner
                      style={{ display: "inline-block", verticalAlign: "middle", margin: "0 0.3rem -5px 0" }}
                      size="12"
                    />
                    Loading count...
                  </div>
                )}
                {!statusCounts.loading && !statusCounts.error && (
                  <>
                    <p style={{ color: colors.red }}>{statusCounts.data.open} open</p>
                    <p style={{ color: colors.vividBlue }}>{statusCounts.data.cleared} cleared</p>
                    <p style={{ color: colors.green }}>{statusCounts.data.closed} closed</p>
                  </>
                )}
              </div>
              <Button
                buttonType={"secondary"}
                style={{ flexBasis: "24%", minWidth: "145px", marginRight: "0.5rem" }}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    punchOwner: "",
                    punchOrigin: "",
                    punchCategory: "",
                    punchLocation: "",
                    system: "",
                    searchterm: "",
                  });
                }}
              >
                Clear Filters
              </Button>
              <Button
                onClick={() => dispatch(showModalPage({ content: <PunchModal refreshData={refreshData} /> }))}
                style={{ flexBasis: "30%", flexShrink: 0, width: "100%" }}
              >
                Add Punch <PlusIcon style={{ margin: "-0.6rem 0 -0.35rem 0" }} />
              </Button>
            </div>

            <div className="row">
              <TextInput
                placeholder="Search"
                name="searchterm"
                onChange={(e) => handleDropdownChange(e, filterData)}
                value={filterData.searchterm}
              />
            </div>
            <div className="row">
              <DropDownList
                placeholder="Punch Owner"
                className="dropdown"
                name="punchOwner"
                value={filterData.punchOwner}
                dropDownListContent={[{ id: "", name: "All punch owners" }, ...metaData.data.punchOwners]}
                onChange={(e) => handleDropdownChange(e, filterData)}
                selectedContentId={filterData.punchOwner}
              />
              <DropDownList
                placeholder="Punch Origin"
                className="dropdown"
                name="punchOrigin"
                value={filterData.punchOrigin}
                dropDownListContent={[{ id: "", name: "All punch origins" }, ...metaData.data.punchOrigins]}
                onChange={(e) => handleDropdownChange(e, filterData)}
                selectedContentId={filterData.punchOrigin}
              />
              {/* Don't display the punch category for SemCompletion RIG */}
              {!isRIG && (
                <DropDownList
                  placeholder="Punch Category"
                  className="dropdown"
                  name="punchCategory"
                  value={filterData.punchCategory}
                  dropDownListContent={[{ id: "", name: "All punch categories" }, ...metaData.data.punchCategories]}
                  onChange={(e) => handleDropdownChange(e, filterData)}
                  selectedContentId={filterData.punchCategory}
                />
              )}
              {!isRIG && (
                <DropDownList
                  placeholder="Punch Location"
                  className="dropdown"
                  name="punchLocation"
                  value={filterData.punchLocation}
                  dropDownListContent={[{ id: "", name: "All punch locations" }, ...metaData.data.platformLocations]}
                  onChange={(e) => handleDropdownChange(e, filterData)}
                  selectedContentId={filterData.punchLocation}
                />
              )}
              <DropDownList
                placeholder="System"
                className="dropdown"
                name="system"
                value={filterData.system}
                dropDownListContent={[{ id: "", name: "All systems" }, ...metaData.data.systems]}
                onChange={(e) => handleDropdownChange(e, filterData)}
                selectedContentId={filterData.system}
              />
            </div>

            <TabBar
              style={{ margin: "0 -1rem -1rem -1rem" }}
              activeTabIndex={activeTabIndex}
              tabs={tabs.map((tab, index) => ({
                title: tab.title,
                color: tab.color,
                onClick: () => setActiveTabIndex(index),
              }))}
            />
          </div>
          <TabView
            onScrollEnd={(tabIndex) => getData({ tabIndex, filterData })}
            activeTabIndex={activeTabIndex}
            tabs={[
              <Tab punches={openPunches} match={props.match} />,
              <Tab punches={clearedPunches} match={props.match} />,
              <Tab punches={closedPunches} match={props.match} />,
            ]}
          />
        </>
      )}
    </Page>
  );
}

const Tab = ({ punches, match }) => {
  const { path } = match;
  const isRIG = path.includes("SemcompletionPunchRIG") ? true : false;

  return (
    <div style={{ padding: "1rem 0" }}>
      {punches.data.length > 0 &&
        !punches.error &&
        punches.data.map((punch) => {
          const {
            category: { name: categoryName = "" } = {},
            location: { name: locationName = "" } = {},
            punchOrigin: { name: punchOriginName = "" } = {},
            punchOwner: { name: punchOwnerName = "" } = {},
            system: { name: systemName = "" } = {},
            workType: { name: workTypeName = "" } = {},
            createdDate,
          } = punch;

          const subTitle = [
            punchOwnerName,
            workTypeName,
            locationName,
            punchOriginName,
            !isRIG ? categoryName : "",
            systemName,
            createdDate.split("T").shift(),
          ].filter((d) => d.trim() !== "");

          return (
            <ListItem
              onClick={() => void pageNavigator(match.url + "/" + punch.id, "forward")}
              title={`${punch.punchList ? `${punch.punchList.idPrefix}  -` : ""} ${punch.punchId} - ${
                punch.title ? punch.title : ""
              }`}
              subTitle={subTitle.join(" - ")}
              iconRight={<ChevronRightIcon />}
              key={punch.id}
            />
          );
        })}
      {punches.loading && !punches.error && <InlineSpinner />}
      {!punches.loading && punches.error && <StatusBox />}
      {!punches.loading && !punches.error && punches.data.length === 0 && (
        <StatusBox title="No punches found" icon={<AlertCircleOutlineIcon />} content="  " />
      )}
    </div>
  );
};

const container = () => css`
  .header {
    background-color: ${colors.white};
    padding: 1rem;

    .punch-count-container {
      flex-basis: 50%;
      padding: 0 0.5rem;
      display: flex;

      p {
        margin-right: 0.5rem;
        font-size: 0.9rem;
        font-weight: 700;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.85rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .dropdown {
        flex-grow: 1;
        margin: 0 0.35rem;
        flex-basis: 25%;

        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  button svg {
    display: inline-block;
    margin-bottom: -0.35rem;
  }
`;

export default PunchOverview;
