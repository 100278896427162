import React, { useState } from "react";
// styling
import { css } from "emotion";
import colors from "../../../style/colors";
import { AccountAlertIcon, AccountCheckIcon, ArrowExpandIcon, CheckIcon, PencilIcon, PlusIcon } from "mdi-react";
import ButtonRounded from "../../ui/ButtonRounded";
import AnimateHeight from "react-animate-height";
import { useDispatch } from "react-redux";
import { showLightBox } from "../../../actions/uiActions";

function PunchActivityLog({ activityLog }) {
  return (
    <div className={container()}>
      {activityLog.map((logEntry) => (
        <LogEntry key={logEntry.id} logEntry={logEntry} />
      ))}
    </div>
  );
}

function LogEntry({ logEntry }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className={"log-entry " + logEntry.action.toLowerCase()}>
      <div className="header">
        <ActionEmblem action={logEntry.action} />

        {logEntry.date && (
          <p className="time">
            {logEntry.date.slice(0, 4)}-{logEntry.date.slice(4, 6)}-{logEntry.date.slice(6, 8)}
          </p>
        )}
        <p className="user">
          {logEntry.user} {logEntry.userGroup}
        </p>
        {(logEntry.comment || logEntry.image) && (
          <ButtonRounded secondary={true} small={true} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Hide" : "Show"} details
          </ButtonRounded>
        )}
      </div>
      <AnimateHeight height={isExpanded ? "auto" : 0} animateOpacity={true}>
        <div className="details-content">
          {logEntry.comment && <p className="comment">{logEntry.comment}</p>}
          {logEntry.image && (
            <div
              className="image-wrapper"
              style={{
                backgroundImage: `url("${logEntry.image}")`,
              }}
            >
              <button className="fullscreen-toggle">
                <ArrowExpandIcon
                  onClick={() => dispatch(showLightBox({ images: [{ baseURL: "", image: logEntry.originalImage }] }))}
                />
              </button>
            </div>
          )}
        </div>
      </AnimateHeight>
    </div>
  );
}

function ActionEmblem({ action }) {
  switch (action) {
    case "CREATE":
      return (
        <div className="status-emblem create">
          <PlusIcon /> Created
        </div>
      );
    case "CLEAR":
      return (
        <div className="status-emblem clear">
          <AccountCheckIcon /> Cleared
        </div>
      );
    case "OPEN":
      return (
        <div className="status-emblem open">
          <AccountAlertIcon /> Opened
        </div>
      );
    case "CLOSE":
      return (
        <div className="status-emblem close">
          <CheckIcon /> Closed
        </div>
      );
    case "EDIT":
      return (
        <div className="status-emblem edit">
          <PencilIcon /> Edited
        </div>
      );
    default:
      return null;
  }
}

const container = () => css`
  .log-entry {
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    padding: 0.65rem 0.5rem;
    margin: 0 0 0.35rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .user {
        flex-grow: 1;
      }
      .time {
        margin-right: 1rem;
        color: ${colors.darkGrey};
      }
    }

    .details-content {
      p.comment {
        margin-top: 0.5rem;
      }

      .image-wrapper {
        margin-top: 0.5rem;
        flex: 1 1 46%;
        min-width: 300px;
        padding-bottom: 40%;
        border: 1px ${colors.midGrey} solid;
        border-radius: 5px;
        background-size: cover;
        background-position: center;
        position: relative;

        button.fullscreen-toggle {
          position: absolute;
          top: 1rem;
          right: 1rem;
          background-color: ${colors.white};
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 1.25rem;
          border: 1px ${colors.lightGrey} solid;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.9;

          &:hover {
            cursor: pointer;
            opacity: 1;
          }

          svg {
            color: ${colors.darkGrey};
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    &.create,
    &.edit {
      border-left: ${colors.darkGrey} 3px solid;
      .status-emblem {
        color: ${colors.darkGrey};
      }
    }
    &.open {
      border-left: ${colors.red} 3px solid;
      .status-emblem {
        color: ${colors.red};
      }
    }
    &.clear {
      border-left: ${colors.vividBlue} 3px solid;
      .status-emblem {
        color: ${colors.vividBlue};
      }
    }
    &.close {
      border-left: ${colors.green} 3px solid;
      .status-emblem {
        color: ${colors.green};
      }
    }
    .status-emblem {
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      width: 100px;

      svg {
        margin-right: 0.35rem;
        margin-left: 0.25rem;
      }
    }
  }
`;

export default PunchActivityLog;
