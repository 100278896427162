// Libs
import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import queryString from 'query-string';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { css } from 'emotion';
import { pdfjs } from 'react-pdf';

// Redux actions
import { checkToken } from './actions/authActions';
import { getPages } from './actions/pagesActions';
import { getAppConfig } from './actions/appConfigActions';
import { getLanguage } from './actions/languageActions';

// Components
import FrontPage from './components/FrontPage';
import InstallationFlow from './components/installationFlow/InstallationFlow';
import LoginPage from './components/login/LoginPage';
import NavigationList from './components/NavigationList';
import Profile from './components/profile/Profile';
import SideMenu from './components/SideMenu';

// Semcompletion
import SemcompletionOverview from './components/semcompletion/SemcompletionOverviewLazy';
import PunchOverview from './components/semcompletion/punch/PunchOverview';
import PunchSingle from './components/semcompletion/punch/PunchSingle';
import JobPackage from './components/semcompletion/JobPackageLazy';
import TestSheet from './components/semcompletion/TestSheetLazy';
import Search from './components/semcompletion/SearchLazy';
import SemcompltionProjectChooser from './components/semcompletion/SemcompletionProjectChooser';
import SemcompletionInitializer from './components/semcompletion/SemcompletionInitializer';
import MasterGroupProjects from './components/semcompletion/admin/masterGroupProjects/Overview';

// semco-time
import WorkTimeOverview from './components/semco-time/WorkTimeOverview';
import AbsenceOverview from './components/semco-time/AbsenceOverview';
import CorrectionOverview from './components/semco-time/CorrectionOverview';
import ForemanRegistration from './components/semco-time/ForemanRegistration';
import ForemanTeamPage from './components/semco-time/ForemanTeam/ForemanTeamPage';
import AdminView from './components/semco-time/AdminView';

// UI Components
import ContextMenu from './components/ui/ContextMenu';
import Dialog from './components/ui/Dialog';
import LoadingPage from './components/ui/LoadingPage';
import ModalPage from './components/ui/ModalPage';
import Lightbox from './components/ui/Lightbox';
import SkeletonPage from './components/ui/SkeletonPage';
import AbsenceOverviewDK from './components/AbsenceDK/AbsenceOverviewDK';
import WorkTimeTable from './components/semco-time/WorkTimeTable';
import WorkTimeOverviewDK from './components/WorkTimeDK/WorkTimeOverviewDK';
import ContactItSupport from './components/ui/ContactItSupport';
import CustomMessageModal from './components/ui/CustomMessageModal';

// Approvals
import TimeSheetCommissioning from './components/WorkTimeDK/customerApproval/TimeSheetCommissioning';
import TimeSheetForeman from './components/WorkTimeDK/customerApproval/TimeSheetForeman';
import TimeSheetSemsupport from './components/WorkTimeDK/customerApproval/TimeSheetSemsupport';
import TimeSheetUnapprovedHours from './components/WorkTimeDK/customerApproval/TimeSheetUnapprovedHours';
import TimesheetsPendingSignature from './components/WorkTimeDK/customerApproval/TimesheetsPendingSignature';
import TimesheetsSigned from './components/WorkTimeDK/customerApproval/TimesheetsSigned';

// Reports
import WorkTimeNptHottReportDK from './components/WorkTimeDK/reports/WorkTimeNptHottReportDK';
import WorkTimePendingApprovalHoursTimesheetDK from './components/WorkTimeDK/reports/WorkTimePendingApprovalHoursTimesheetDK';
import WorkTimePendingApprovalHoursTimesheetTeamSelectedDK from './components/WorkTimeDK/reports/WorkTimePendingApprovalHoursTimesheetTeamSelectedDK';

// Corrections
import WorktimeCorrectionsTimeAppSide from './components/WorkTimeDK/corrections/WorktimeCorrectionsTimeAppSide';
import WorkTimeCorrectionsIFSSide from './components/WorkTimeDK/corrections/WorkTimeCorrectionsIFSSide ';

// Utilities
import history from './utilities/navigation-history';

// actions
import { showDialog } from './actions/uiActions';

// icons
import { AlertDecagramIcon } from 'mdi-react';

import 'antd/dist/antd.css';
import './global.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStandalone: false,
			isAndroid: false,
			isIOS: false,
			showInstallationGuide: false
		};
	}

	componentDidMount() {
		this.checkPotentialRedirect();
		this.props.getAppConfig();
		this.props.checkToken();
		this.findUserAgent();
		this.detectStandalone();
		document.querySelector('.hardcoded-spinner').remove();

		// Check if app i installed to home screen. Otherwise show dialog to prompt user to install it
		// setTimeout(() => {
		//   if (process.env.NODE_ENV === "development") return;

		//   // Get potential user from query-string
		//   let queryStrings = queryString.parse(window.location.search);

		//   // Cancels installation guide
		//   if (Number(queryStrings.ignoreInstallationGuide) === 1 || String(queryStrings.ignoreInstallationGuide) === "true")
		//     return;

		//   if (!this.state.isStandalone && (this.state.isIOS || this.state.isAndroid) && this.props.auth.isAuthenticated) {
		//     // If app is not installed and the device is either ios or android but the user is logged in, show installation help prompt
		//     this.displayAddToHomeScreenDialog();
		//   } else if (
		//     (!this.state.isStandalone && (this.state.isIOS || this.state.isAndroid) && !this.props.auth.isAuthenticated) ||
		//     queryStrings.showInstallationScreen
		//   ) {
		//     // If app is not installed and the device is either ios or android or a querystring is present in url and the
		//     // user is not logged in, show installation guide
		//     this.setState({ showInstallationGuide: true });
		//   }
		// }, 0);
	}

	checkPotentialRedirect() {
		let { hostname, pathname } = window.location;
		// Redirects from root to semcoTIme
		// if (
		// 	(hostname === 'semcotime.com' || hostname === 'test-semcotime.com') &&
		// 	(pathname === '' || pathname === '/')
		// ) {
		// 	window.location.replace(`https://${window.location.host}/semcotime`);
		// }

		if (
			hostname === 'semcotime.com' &&
			(pathname === '' || pathname === '/' || pathname === '/semcotime' || pathname === '/semcotime-dk')
		) {
			window.location.replace(`https://semcotimedknoapp.semcomaritime.com/${pathname}`);
		}

		if (
			hostname === 'test-semcotime.com' &&
			(pathname === '' || pathname === '/' || pathname === '/semcotime' || pathname === '/semcotime-dk')
		) {
			window.location.replace(`https://semcotimedknoapp-test.semcomaritime.com/${pathname}`);
		}

		// Redirects from root to semcompletion
		if (
			(hostname === 'semcompletion.com' || hostname === 'test-semcompletion.com') &&
			(pathname === '' || pathname === '/')
		) {
			window.location.replace(`https://${window.location.host}/semcompletion`);
		}
	}

	// Denne function er oplagt til at lave som custom hook med state variablerne isIOS og isAndroud. Det kræver bare at App classen skrives om til et Function component
	findUserAgent() {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;
		this.setState({ isIOS: userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false });
		this.setState({ isAndroid: userAgent.match(/Android/) !== null ? true : false });
	}

	detectStandalone() {
		this.setState({ isStandalone: window.matchMedia('(display-mode: standalone)').matches });
	}

	displayAddToHomeScreenDialog() {
		let { lang, showDialog } = this.props;

		showDialog({
			icon: <AlertDecagramIcon />,
			title: lang.installationHeyThere,
			content: lang.dialogNotAddedToHomescreenContent,
			primaryActionTitle: lang.dialogNotAddedToHomescreenPrimaryAction,
			primaryAction: () => this.setState({ showInstallationGuide: true }),
			secondaryActionTitle: lang.dialogNotAddedToHomescreenSecondaryAction
		});
	}

	closeInstallationGuide = () => {
		this.setState({ showInstallationGuide: false });
	};

	render() {
		/**
		 * States:
		 * ---
		 * 1) User is not logged in, app is not running standalone and device is iOS or Android
		 *    -> Show installation flow
		 *
		 * 2) User is not logged in, device is not iOS or android
		 *    -> Show login screen
		 *
		 * 3) User is logged in (if not standalong AND ios or android also show installation-flow dialog)
		 *    -> Show frontpage
		 */

		let { transitionDirection, auth, lang, pages, appConfig, frontPageImage, isAuthenticated, checkingToken } =
			this.props;

		// If some of the following content isn't available wait for it before showing the app
		// pages
		// appConfig
		// language

		// 0
		if (
			appConfig.apiUrl === '' ||
			appConfig.loading === true ||
			frontPageImage.baseURL === '' ||
			(!lang && !pages.length && !appConfig)
		) {
			return <LoadingPage />;
		}

		// 1
		if (lang && this.state.showInstallationGuide) {
			return <InstallationFlow onCloseGuide={this.closeInstallationGuide} />;
		}

		if (checkingToken === false && isAuthenticated === false && !this.state.showInstallationGuide) {
			return <LoginPage />;
		}

		window.Rollbar.configure({
			enabled: process.env.NODE_ENV === 'production' ? true : false,
			captureIp: 'anonymize',
			payload: {
				person: {
					id: auth.user.id,
					username: auth.user.name
				}
			}
		});

		return (
			<div className={componentStyle()} data-app-root>
				<Router history={history}>
					<Route
						render={({ location }) => [
							<TransitionGroup key={0}>
								<CSSTransition
									key={location.key}
									classNames={`page-${transitionDirection}`}
									timeout={240}>
									<Suspense fallback={<SkeletonPage />}>
										<Switch location={location}>
											{/* general */}
											<Route
												exact
												key={978}
												path="/:appname/Profile/:userId"
												component={Profile}
											/>
											<Route
												exact
												key={979}
												path="/:appname/NavigationList/:pageId/"
												component={NavigationList}
											/>
											{/* Semco-time DK */}
											<Route
												exact
												key={980}
												path="/:appname/SemcotimeWorktimeDK/:pageId/"
												component={WorkTimeOverviewDK}
											/>
											<Route
												exact
												key={981}
												path="/:appname/SemcotimeAbsenceDK/:pageId/"
												component={AbsenceOverviewDK}
											/>
											{/* Semco-time */}
											<Route
												exact
												key={982}
												path="/:appname/SemcotimeWorktime/:pageId/"
												component={WorkTimeOverview}
											/>
											<Route
												exact
												key={983}
												path="/:appname/SemcoTimeCorrections/:pageId/"
												component={CorrectionOverview}
											/>
											<Route
												exact
												key={984}
												path="/:appname/SemcoTimeAbsence/:pageId/"
												component={AbsenceOverview}
											/>
											<Route
												exact
												key={985}
												path="/:appname/SemcoTimeWorktimeTable/:pageId/"
												component={WorkTimeTable}
											/>
											<Route
												exact
												key={986}
												path="/:appname/SemcoTimeForeman/:pageId/"
												component={ForemanRegistration}
											/>
											<Route
												exact
												key={987}
												path="/:appname/SemcoTimeAdminView/:pageId/"
												component={AdminView}
											/>
											<Route
												exact
												key={979}
												path="/:appname/SemcoTimeForemanTeamSelector/:pageId/"
												component={ForemanTeamPage}
											/>
											<Route
												exact
												key={970}
												path="/:appname/SemcoTimeUnapprovedTimesheet/:pageId/"
												render={props => (
													<TimeSheetUnapprovedHours {...props} user={auth.user} />
												)}
											/>
											{/* Timesheet Hours approved by Foreman */}
											<Route
												exact
												key={990}
												path="/:appname/SemcoTimeTimesheetForeman/:pageId/"
												render={props => <TimeSheetForeman {...props} user={auth.user} />}
											/>
											{/* Timesheet Approved Hours - Commisioning */}
											<Route
												exact
												key={988}
												path="/:appname/SemcoTimeTimesheetCommissioning/:pageId/"
												render={props => <TimeSheetCommissioning {...props} user={auth.user} />}
											/>
											{/* Timesheet Hours approved by Semsupport */}
											<Route
												exact
												key={989}
												path="/:appname/SemcoTimeTimesheetSemsupport/:pageId/"
												render={props => <TimeSheetSemsupport {...props} user={auth.user} />}
											/>
											<Route
												exact
												key={991}
												path="/:appname/SemcoTimePendingApprovalHours/:pageId/"
												render={props => (
													<WorkTimePendingApprovalHoursTimesheetDK
														{...props}
														user={auth.user}
													/>
												)}
											/>
											<Route
												exact
												key={992}
												path="/:appname/SemcoTimePendingApprovalHoursTeamSelected/:pageId/"
												render={props => (
													<WorkTimePendingApprovalHoursTimesheetTeamSelectedDK
														{...props}
														user={auth.user}
													/>
												)}
											/>
											<Route
												exact
												key={993}
												path="/:appname/SemcoTimeCorrectionsTimeApp/:pageId/"
												render={props => (
													<WorktimeCorrectionsTimeAppSide {...props} user={auth.user} />
												)}
											/>

											<Route
												exact
												key={994}
												path="/:appname/SemcoTimeCorrectionsIFS/:pageId/"
												render={props => (
													<WorkTimeCorrectionsIFSSide {...props} user={auth.user} />
												)}
											/>
											<Route
												exact
												key={995}
												path="/:appname/SemcoTimeNptHottReport/:pageId/"
												render={props => (
													<WorkTimeNptHottReportDK {...props} user={auth.user} />
												)}
											/>
											<Route
												exact
												key={800}
												path="/:appname/SemcoTimePendingSignature/:pageId/"
												render={props => (
													<TimesheetsPendingSignature {...props} user={auth.user} />
												)}
											/>
											<Route
												exact
												key={801}
												path="/:appname/SemcoTimeSignedTimesheets/:pageId/"
												render={props => <TimesheetsSigned {...props} user={auth.user} />}
											/>

											{/* Default and frontpage */}
											<Route exact key={983} path="/:appname/FrontPage" component={FrontPage} />
											<Route exact key={983} path="/:appname/" component={FrontPage} />
											{/************************ START OF SEMCOMPLETION ROUTES ************************/}
											<Route
												exact
												key="sc_01"
												path="/:appname/SemcompletionOverview/:pageId/:jobPackageType"
												component={SemcompletionOverview}
											/>
											<Route
												exact
												key="sc_02"
												path="/:appname/SemcompletionOverview/:pageId/:jobPackageType/:jobPackageId"
												component={JobPackage}
											/>
											{/* Test sheet for MC cables */}
											<Route
												exact
												key="sc_03"
												path="/:appname/SemcompletionOverview/:pageId/:jobPackageType/:jobPackageId/:taskId/test-sheet"
												component={TestSheet}
											/>
											<Route
												exact
												key="sc_04"
												path="/:appname/SemcompletionOverview/:pageId/:jobPackageType/:jobPackageId/:taskId/test-sheet"
												component={TestSheet}
											/>
											{/* Project search */}
											<Route
												exact
												key="sc_05"
												path="/:appname/SemcompletionSearch/:pageId"
												component={Search}
											/>
											{/* Punch */}
											<Route
												exact
												key="sc_06"
												path="/:appname/SemcompletionPunch/:pageId"
												component={PunchOverview}
											/>
											<Route
												exact
												key="sc_07"
												path="/:appname/SemcompletionPunch/:pageId/:id"
												component={PunchSingle}
											/>
											{/* Punch RIG */}
											<Route
												exact
												key="sc_08"
												path="/:appname/SemcompletionPunchRIG/:pageId"
												component={PunchOverview}
											/>
											<Route
												exact
												key="sc_09"
												path="/:appname/SemcompletionPunchRIG/:pageId/:id"
												component={PunchSingle}
											/>
											<Route
												exact
												key="sc_10"
												path="/:appName/SemcompletionProjectChooser/:pageId/"
												component={SemcompltionProjectChooser}
											/>
											{/* Admin */}
											<Route
												exact
												key="sc_11"
												path="/:appname/SemcompletionMastergroupCustomers/:pageId"
												component={MasterGroupProjects}
											/>
											{/************************ END OF SEMCOMPLETION ROUTES ************************/}
										</Switch>
									</Suspense>
								</CSSTransition>
							</TransitionGroup>
						]}
					/>
				</Router>

				{/* General UI components */}
				<SideMenu />
				<ModalPage />
				<ContextMenu />
				<Dialog />
				<Lightbox />
				<ContactItSupport user={auth.user} />

				{localStorage.getItem('hideCustomMessageModal2') !== 'true' && appConfig.title === 'SemcoTime DK' && (
					<CustomMessageModal />
				)}

				{pages.pages.length > 0 && !pages.loading && <SemcompletionInitializer />}
			</div>
		);
	}
}

const componentStyle = () => css`
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;

	p {
		margin-bottom: 0px;
	}

	.ant-radio-group {
		padding: 9px 0px;
		width: 100%;
	}

	.ant-checkbox-wrapper {
		padding: 15px 0px;
	}
`;

const mapStateToProps = state => ({
	transitionDirection: state.pages.transitionDirection,
	pages: state.pages,
	appConfig: state.appConfig,
	frontPageImage: state.appConfig.frontPageImage,
	auth: state.auth,
	checkingToken: state.auth.checkingToken,
	isAuthenticated: state.auth.isAuthenticated,
	lang: state.language.language
});

const mapDispatchToProps = dispatch => ({
	getPages: bindActionCreators(getPages, dispatch),
	getAppConfig: bindActionCreators(getAppConfig, dispatch),
	getLanguage: bindActionCreators(getLanguage, dispatch),
	checkToken: bindActionCreators(checkToken, dispatch),
	showDialog: bindActionCreators(showDialog, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
