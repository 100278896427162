import React, { useState, useEffect, useRef } from "react";
import { CellphoneIphoneIcon, ClipboardTextIcon } from "mdi-react";
import PasswordDots from "./PasswordDots";
import Numpad from "../ui/Numpad";
import keyPressProxy from "./utilities/keyPressProxy";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../actions/authActions";

import { Button, notification } from "antd";

const PhoneLogin = (props) => {
  const dispatch = useDispatch();
  const signingIn = useSelector((s) => s.auth.signingIn);
  const regex = /^[+]?\d+$/;

  const [state, setState] = useState({
    phone: "",
    password: "",
    phoneEntered: false,
    passwordEntered: false,
    showNumpad: true,
  });

  const refProps = useRef(props);
  const refState = useRef(state);
  useEffect(() => {
    refState.current = state;
    refProps.current = props;
  }, [state, props]);

  useEffect(() => {
    if (state.phone) localStorage.setItem("last-used-phone-login", state.phone);
  }, [state]);

  useEffect(() => {
    // Try to get last used phone
    let potentialPhoneNumber = localStorage.getItem("last-used-phone-login");
    if (potentialPhoneNumber) setState({ ...state, phone: potentialPhoneNumber, phoneEntered: true });

    // Bind event listeners for keys
    document.addEventListener("keyup", keyProxy);
    document.addEventListener("keydown", handleTabKey);

    return () => {
      // Unbind event listeners for keys
      document.removeEventListener("keyup", keyProxy);
      document.removeEventListener("keydown", handleTabKey);
    };
    // eslint-disable-next-line
  }, []);

  let { showNumpad, phone, password, phoneEntered } = state;

  function handleTabKey(e) {
    if (refProps.current.enableInput === false) return;

    // Moves focus between phone and password with tab and shift-tab
    let { phoneEntered } = refState.current;

    // If tab is pressed and phone is not entered (and mode is phone)
    if (e.key === "Tab" || (e.key === "Enter" && !phoneEntered)) {
      e.preventDefault();
      setState({ ...refState.current, phoneEntered: true });
    }

    if (e.shiftKey && e.key === "Tab" && phoneEntered) {
      e.preventDefault();
      setState({ ...refState.current, phoneEntered: false });
    }
  }

  function keyProxy(e) {
    handleKeyPress(keyPressProxy(e));
  }

  function handleKeyPress(key) {
    if (refProps.current.enableInput === false) return;

    let freshState = refState.current;
    let { phoneEntered, passwordEntered, password, phone } = refState.current;

    // Handle delete button
    if (key === "delete") {
      if (!phoneEntered) {
        setState({ ...freshState, phone: freshState.phone.slice(0, -1) });
      } else if (phoneEntered && !passwordEntered && password.length > 0) {
        setState({ ...freshState, password: password.slice(0, -1) });
      } else if (phoneEntered && passwordEntered) {
        setState({ ...freshState, passwordEntered: false });
      } else if (phoneEntered && !passwordEntered) {
        setState({ ...freshState, phoneEntered: false });
      }
      return;
    }
    // If logintype is phone and phone isn't entered, append values to phone
    if (!phoneEntered) {
      setState({ ...freshState, phone: freshState.phone + key });

      // If logintype is phone and phone is entered, append values to password (as long as theres is 3 or less characters)
    } else if (phoneEntered && key !== "delete" && key !== "enter" && !passwordEntered && password.length < 3) {
      setState({ ...freshState, password: password + key });

      // If logintype is phone and phone is entered, and the password is exactly 3 characters long, the current character is
      // the last in the password. Therefore append it to the password and automatically try to login
    } else if (phoneEntered && key !== "delete" && key !== "enter" && !passwordEntered && password.length === 3) {
      setState({ ...freshState, password: password + key, passwordEntered: true });
      tryLogin({
        phone,
        password: password + key,
      });
    }

    function tryLogin(credentials) {
      dispatch(signIn(credentials));

      setState({
        ...refState.current,
        password: "",
        passwordEntered: false,
      });
    }
  }

  function handlePaste() {
    if (navigator.clipboard) {
      navigator.clipboard
        .readText()
        .then((clipboardText) => {
          if (regex.test(clipboardText)) {
            setState({
              ...state,
              phone: clipboardText,
              phoneEntered: true,
            });
          } else {
            notification.error({
              duration: 7,
              message: "Invalid Phone Number",
              description: `Please copy a valid phone number and try again.`,
            });
          }
        })
        .catch((error) => {
          notification.error({
            duration: 7,
            message: "FAILED",
            description: "Couldn't read clipboard",
          });
        });
    } else {
      notification.error({
        duration: 7,
        message: "FAILED",
        description: "Clipboard API not supported in this browser",
      });
    }
  }

  return (
    <>
      <div
        className="input phone"
        onClick={() =>
          setState({
            ...state,
            phoneEntered: false,
          })
        }
      >
        <CellphoneIphoneIcon />
        <p>{phone}</p>
        {phone.length !== 0 && !phoneEntered && <div className="phone-cursor" />}
        {phone.length === 0 && (
          <Button onClick={handlePaste} type="link">
            Paste Phone Number
          </Button>
        )}
      </div>
      <PasswordDots
        hide={signingIn}
        active={phoneEntered}
        password={password}
        onClick={() => {
          setState({
            ...state,
            phoneEntered: true,
          });
        }}
      />

      <Numpad active={showNumpad} onKeyPress={(key) => handleKeyPress(key)} />
    </>
  );
};

export default PhoneLogin;
