import React, { useEffect, useState } from "react";
import { chunk, isEmpty } from "lodash";
import { notification } from "antd";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";
// utlities
import req from "../../../utilities/request-utility";
import getErrorMessage from "../../../utilities/get-error-message";

//actions

// ui-Components
import DropDown from "../../ui/DropDown";
import TextInput from "../../ui/TextInput";
import TextareaInput from "../../ui/TextareaInput";
import Button from "../../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import ScrollView from "../../ui/ScrollView";
import ImageUploadMultiple from "../ImageUploadMultiple";
import { hideModalPage } from "../../../actions/uiActions";

import InlineSpinner from "../../ui/InlineSpinner";

import useOverviewData from "./hooks/useOverviewData";

function PunchModal({
  refreshData,
  initialFormData = {},
  testSheetData = {},
  editMode = false,
  testSheetMode = false,
  afterFormSubmitHook = () => {},
}) {
  const dispatch = useDispatch();
  const { project = null } = useSelector(({ semcompletion }) => ({ project: semcompletion.selectedProject }));
  const { metaData } = useOverviewData();

  const isRIG = project ? (project.projectType === "02B" ? true : false) : false;

  const [formData, setFormData] = useState({
    punchId: initialFormData.punchId || "",
    punchList: initialFormData.punchList || "",
    punchOwner: initialFormData.punchOwner || "",
    punchOrigin: initialFormData.punchOrigin || "",
    punchCategory: initialFormData.punchCategory || "",
    platformLocation: initialFormData.platformLocation || "",
    platformLocationText: initialFormData.platformLocationText || "",
    workType: initialFormData.workType || "",
    tagNumber: initialFormData.tagNumber || "",
    system: initialFormData.system || "",
    title: initialFormData.title || "",
    description: initialFormData.description || "",
    name: initialFormData.name || "",
    images: initialFormData.images || [],
  });
  const [formLoading, setFormloading] = useState(false);

  useEffect(() => {
    if (metaData.loading) return;
    if (!isEmpty(initialFormData)) return;

    // Set defaults to punchList
    let defaultPunchList = "";
    if (!initialFormData.punchList && metaData.data.punchLists.length !== 0)
      defaultPunchList = metaData.data.punchLists[0].id;

    // This sets up all the data needed when adding punches from a testsheet
    if (testSheetMode === true) {
      let [preSelectedCategory] = metaData.data.punchCategories.filter((c) => c.name.toLowerCase() === "b");
      let [preSelectedOrigin] = metaData.data.punchOrigins.filter((o) => o.name.toLowerCase() === "construction");

      setFormData({
        ...formData,
        punchCategory: preSelectedCategory ? preSelectedCategory.id : "",
        punchList: defaultPunchList,
        punchOrigin: preSelectedOrigin ? preSelectedOrigin.id : "",
        taskId: testSheetData ? testSheetData.taskId : "",
        tagNumber: testSheetData ? testSheetData.cableNumber : "",
        jobPackageId: testSheetData ? testSheetData.jobPackageId : "",
        questionAnswerValue: testSheetData ? testSheetData.answerValue : "",
        questionId: testSheetData ? testSheetData.questionId : "",
      });
    } else {
      setFormData({ ...formData, punchList: defaultPunchList });
    }

    // eslint-disable-next-line
  }, [JSON.stringify(metaData), JSON.stringify(initialFormData)]);

  function isFormValid(formDate) {
    if (
      (!isRIG && !formDate.punchList) ||
      !formDate.punchOwner ||
      !formDate.punchOrigin ||
      (!isRIG && !formDate.punchCategory) ||
      !formDate.system ||
      !formDate.name ||
      !formDate.title
    ) {
      return {
        status: false,
        message: `Make sure to fill out punch list, punch owner, punch origin, ${
          !isRIG ? "punch category," : ""
        } issue type, title, system description and name and then try again`,
      };
    } else if (!formDate.workType && !formDate.description) {
      return {
        status: false,
        message: "Make sure to fill out at least issue type or description and then try again",
      };
    }

    return { status: true };
  }

  function savePunch() {
    const isFormValidData = isFormValid(formData);

    if (!isFormValidData.status)
      return notification.error({ duration: 7, message: "FAILED", description: isFormValidData.message });

    setFormloading(true);

    if (editMode) {
      req().put(`semcompletion/v2/punches/${initialFormData.id}`, formData).then(handleOk).catch(handleError);
    } else {
      req().post(`semcompletion/v2/punches/`, formData).then(handleOk).catch(handleError);
    }

    function handleOk() {
      dispatch(hideModalPage());
      if (refreshData) refreshData();
      afterFormSubmitHook();
      setFormloading(false);

      notification.success({ duration: 7, message: "SUCCESS", description: "You have successfully added a punch" });
    }

    function handleError(err) {
      const error = getErrorMessage(err);

      notification.error({ duration: 7, message: "FAILED", description: error });

      afterFormSubmitHook();
      setFormloading(false);
    }
  }

  function onChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const form =
    metaData.loading !== 0
      ? chunk(
          [
            initialFormData.punchId
              ? { disabled: true, id: "punchId", label: "Punch no", required: true, type: "text" }
              : null,
            !isRIG ? { id: "punchList", label: "Punch list", options: metaData.data.punchLists, type: "dropdown" } : null,
            { id: "punchOwner", label: "Punch owner", options: metaData.data.punchOwners, type: "dropdown" },
            { id: "punchOrigin", label: "Punch origin", options: metaData.data.punchOrigins, type: "dropdown" },
            // Don't display the punch category for SemCompletion RIG
            !isRIG
              ? { id: "punchCategory", label: "Punch category", options: metaData.data.punchCategories, type: "dropdown" }
              : null,
            !isRIG
              ? {
                  id: "platformLocation",
                  label: "Punch location",
                  options: metaData.data.platformLocations,
                  type: "dropdown",
                }
              : {
                  id: "platformLocationText",
                  label: "Punch location",
                  type: "text",
                },
            { id: "workType", label: "Issue type", options: metaData.data.workTypes, type: "dropdown" },
            !isRIG ? { id: "tagNumber", label: "Tag number / Procedure", type: "text" } : null,
            { id: "system", label: "System", options: metaData.data.systems, type: "dropdown" },
          ].filter((d) => d),
          2
        )
      : [];

  const renderDropdownLabels = (column) => {
    let required = "";
    if (
      column.id === "punchList" ||
      column.id === "punchOrigin" ||
      column.id === "punchOwner" ||
      column.id === "punchCategory" ||
      column.id === "system" ||
      column.required
    ) {
      required = <span className="required">*</span>;
    }
    return (
      <label htmlFor="">
        {column.label}
        {required}
      </label>
    );
  };

  return (
    <ScrollView className={container()}>
      {form.map((row, index) => (
        <div className="row" key={`form-row-${index}`}>
          {row.map((column) => {
            const { disabled = false } = column;
            return (
              <div className="col-50" key={column.label}>
                <label htmlFor="">{renderDropdownLabels(column)}</label>
                {column.type === "dropdown" && (
                  <DropDown
                    name={column.id}
                    onChange={onChange}
                    options={column.options ? column.options.map((d) => ({ label: d.name, value: d.id })) : []}
                    placeholder="Click to choose"
                    value={formData[column.id]}
                  />
                )}
                {column.type === "text" && (
                  <TextInput
                    placeholder={`Enter ${column.label}`}
                    disabled={disabled}
                    name={column.id}
                    onChange={onChange}
                    value={formData[column.id]}
                  />
                )}
              </div>
            );
          })}
        </div>
      ))}

      <div className="row">
        <div className="col-100">
          <label htmlFor="">
            Title<span className="required">*</span>
          </label>
          <TextInput value={formData.title} name="title" onChange={onChange} />
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <label htmlFor="">Description</label>
          <TextareaInput value={formData.description} name="description" onChange={onChange} />
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <label htmlFor="">Image</label>
          <ImageUploadMultiple
            placeholder="Upload image"
            allowedAmountOfImages={5}
            style={{ marginBottom: "0.75rem" }}
            urlPrefix="/semcompletion"
            uploadedFiles={formData && formData.images}
            onFileUpdate={(files) => {
              setFormData({ ...formData, images: files });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <label htmlFor="">
            Name<span className="required">*</span>
          </label>
          <TextInput value={formData.name} name="name" onChange={onChange} />
        </div>
      </div>
      <div className="row">
        <div className="col-100">
          <Button
            disabled={formLoading}
            onClick={() => {
              if (!project) {
                notification.error({
                  duration: 7,
                  message: "FAILED",
                  description: `There's no selected Project, please select from the main menu`,
                });
              } else {
                savePunch();
              }
            }}
          >
            {formLoading && <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />}{" "}
            {editMode ? "Edit" : "Create"} punch
          </Button>
        </div>
      </div>
    </ScrollView>
  );
}

const container = () => css`
  background-color: ${colors.white};
  padding: 1rem 0;

  label {
    font-weight: 700;
    margin-bottom: 0.35rem;
    display: block;
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  .required {
    color: #808080;
  }
`;

export default PunchModal;
