import React from "react";
import { chunk } from "lodash";
import nl2br from "react-nl2br";

// styling
import { css } from "emotion";
import colors from "../../../style/colors";
//actions

// ui-Components
import InlineSpinner from "../../ui/InlineSpinner";
import Page from "../../ui/Page";
import { AlertDecagramIcon, ArrowBackIcon, ArrowExpandIcon, PencilIcon } from "mdi-react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../ui/TopBar";
import pageNavigator from "../../../utilities/page-navigator";
import { showLightBox, showModalPage } from "../../../actions/uiActions";
import PunchModal from "./PunchModal";
import PunchSingleHeader from "./PunchSingleHeader";
import PunchActivityLog from "./PunchActivityLog";
import ScrollView from "../../ui/ScrollView";
import usePunchSingleData from "./hooks/usePunchSingleData";
import punchStatuses from "../../../config/punchStatuses";
import common from "../../../style/common";
import StatusBox from "../../ui/StatusBox";

function PunchOverview(props) {
  const dispatch = useDispatch();
  const selectedProject = useSelector((s) => s.semcompletion.selectedProject);
  const { punch, punchAllowances, getPunch } = usePunchSingleData(props.match.params.id);

  const isRIG = selectedProject.projectType === "02B" ? true : false;

  const {
    punchList = {},
    punchOwner = {},
    punchOrigin = {},
    category = {},
    location = {},
    workType = {},
    tagNumber = "",
    system = {},
    description = "",
    title = {},
    createdBy = "",
  } = punch.data;

  let punchData = [];
  if (!punch.loading && !punchAllowances.loading && !punch.error) {
    punchData = chunk(
      [
        { label: "Title", value: title },
        { label: "Punch no.", value: punch.data.punchId },
        { label: "Punch list", value: punchList.name },
        { label: "Punch owner", value: punchOwner.name },
        { label: "Punch origin", value: punchOrigin.name },
        // Don't display the punch category for SemCompletion RIG
        !isRIG ? { label: "Punch category", value: category.name } : null,
        { label: "Punch location", value: location.name },
        { label: "Issue type", value: workType.name },
        { label: "Tag number / Procedure", value: tagNumber },
        { label: "System", value: system.name },
        { label: "Description", value: description },
      ].filter((d) => d),
      2
    );
  }

  return (
    <Page backgroundColor={colors.white} className={container()}>
      <TopBar
        title={`Punch (${selectedProject.number} - ${selectedProject.description})`}
        actionLeft={
          <ArrowBackIcon
            onClick={() => {
              props.location.state && props.location.state.pageId === "1115"
                ? props.history.goBack()
                : pageNavigator("BACK", "backward");
            }}
          />
        }
        actionRight={
          !punch.loading &&
          !punch.error &&
          !punchAllowances.loading &&
          !punchAllowances.error &&
          punchAllowances.data.canEdit &&
          punch.data.status === punchStatuses.open ? (
            <button
              small="true"
              className="edit-button"
              onClick={() =>
                dispatch(
                  showModalPage({
                    content: (
                      <PunchModal
                        initialFormData={{
                          id: punch.data.id,
                          punchId: punch.data.punchId,
                          punchList: punchList.id,
                          punchOwner: punchOwner.id,
                          punchOrigin: punchOrigin.id,
                          punchCategory: category.id,
                          platformLocation: location.id ? location.id : "",
                          platformLocationText: location,
                          workType: workType.id,
                          tagNumber: tagNumber,
                          system: system.id,
                          description: description,
                          title: title,
                          name: createdBy,
                          images: punch.data.images,
                        }}
                        editMode={true}
                        refreshData={getPunch}
                      />
                    ),
                    title: `Punch (${selectedProject.number} - ${selectedProject.description})`,
                  })
                )
              }
            >
              <PencilIcon /> Edit
            </button>
          ) : null
        }
      />

      {punch.loading && <InlineSpinner />}

      {!punch.loading && !punchAllowances.loading && punch.error && (
        <StatusBox
          style={{ paddingTop: "2rem" }}
          title="Punch not found"
          content="Punch not found"
          icon={<AlertDecagramIcon />}
        />
      )}

      {!punch.loading && !punchAllowances.loading && !punch.error && (
        <>
          <PunchSingleHeader punch={punch.data} punchAllowances={punchAllowances.data} getPunch={getPunch} />

          <ScrollView style={{ padding: "1rem 0" }}>
            {/* Punch data */}
            <div className="row">
              <div className="col-100">
                <h2>Punch data</h2>
              </div>
            </div>
            {punchData.map((row, index) => {
              return (
                <div className="row" key={`punch-data-row-${index}`}>
                  {row.map((column) => (
                    <div className="col-50" key={column.label}>
                      <p>
                        <span className="label">{column.label}</span>
                        {column.label === "Description" ? <p>{nl2br(column.value)}</p> : column.value}
                      </p>
                    </div>
                  ))}
                </div>
              );
            })}

            <div className="image-container">
              {punch.data.images.map((image) => (
                <div
                  className="image-wrapper"
                  style={{
                    backgroundImage: `url("${image.image}")`,
                  }}
                >
                  <button className="fullscreen-toggle">
                    <ArrowExpandIcon
                      onClick={() =>
                        dispatch(
                          showLightBox({
                            images: [
                              {
                                baseURL: "",
                                image: image.originalImage,
                              },
                            ],
                          })
                        )
                      }
                    />
                  </button>
                </div>
              ))}
            </div>

            <div className="seperator" style={{ marginBottom: "1rem" }} />

            {/* Activity log */}
            <div className="row">
              <div className="col-100">
                <h2>Activity Log</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-100">
                <PunchActivityLog activityLog={punch.data.activityLog} />
              </div>
            </div>
          </ScrollView>
        </>
      )}
    </Page>
  );
}

const container = () => css`
  .seperator {
    border-bottom: 1px ${colors.midGrey} solid;
  }

  .edit-button {
    display: block;
    height: ${common.topBarHeight}px;
    line-height: ${common.topBarHeight}px;
    padding: 0 1rem;
    background-color: transparent;
    font-size: 1rem;
    color: ${colors.white};
    border: 0px;

    svg {
      color: ${colors.white};
      width: 1rem;
      height: 1rem;
      padding: 0;
      margin-bottom: -2px;
      margin-right: 0.15rem;
    }
  }

  .image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 2rem auto;
    padding: 0 0.5rem;

    .image-wrapper {
      flex: 1 1 46%;
      min-width: 300px;
      padding-bottom: 40%;
      border: 1px ${colors.midGrey} solid;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      margin: 0.5rem;
      position: relative;

      button.fullscreen-toggle {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: ${colors.white};
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        border: 1px ${colors.lightGrey} solid;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.9;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        svg {
          color: ${colors.darkGrey};
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .row {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 1.25rem auto;

    .col-50 {
      flex-basis: 49%;
    }

    .col-100 {
      flex-basis: 100%;
    }

    p {
      color: ${colors.black};
    }

    span.label {
      font-weight: 700;
      color: ${colors.black};

      &:after {
        content: ": ";
      }
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .col-50 {
        flex-basis: 100%;

        &:nth-child(odd) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
`;

export default PunchOverview;
